import React from 'react';

const NotFound = (props) => {

    return (
        <>
            <div className="container py-5 mb-lg-3">
                <div className="row justify-content-center pt-lg-4 text-center">
                    <div className="col-lg-5 col-md-7 col-sm-9">
                        <h1 className="display-404">404</h1>
                        <h2 className="h3 mb-4">Página não encontrada.</h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;