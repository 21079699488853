import ClientStore from './ClientStore';
import CarrinhoStore from './CarrinhoStore';
import PedidoStore from './PedidoStore';
import ProdutoStore from './ProdutoStore';
import HeaderStore from './HeaderStore';

const stores = {
    ClientStore,
    CarrinhoStore,
    PedidoStore,
    ProdutoStore,
    HeaderStore
};

export default stores;