import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import api from '../services/api';
import { useAlert } from 'react-alert';

import PageTitle from '../components/PageTitle';
import AsideProfile from '../components/Profile/AsideProfile';
import ModalAddress from '../components/Profile/ModalAddress';
import ItemAddress from '../components/Profile/ItemAddress';
import Loading from '../components/Loading';
import Toolbar from '../components/Profile/Toolbar';
import Skeleton from 'react-loading-skeleton';


const Addresses = (props) => {

  const alert = useAlert();
  const { dados, token } = props.ClientStore;

  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const getMyAddresses = async () => {
    try {
      setLoading(true);
      const response = await api.get('/endereco', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.data.errors) {
        setAddresses(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (token) {
      getMyAddresses();
    }
  }, [token]);

  return (
    <>
      <PageTitle nameRoute="Endereços" url={'/enderecos'} title="Meus endereços" />

      <div className="container pb-5 mb-2 mb-md-3">
        <div className="row">
          {/* <!-- Sidebar--> */}
          <AsideProfile
            type='addresses'
            email={dados.email}
            name={dados.nome}
          />
          {/* { console.log(dados.rua) } */}
          {/* <!-- Content  --> */}
          <section className="col-lg-8">
            {/* <!-- Toolbar--> */}
            <Toolbar title="Lista dos seus endereços cadastrados:" />


            {/* <!-- Addresses list--> */}
            <div className="table-responsive font-size-md">
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Endereço</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    addresses.map((item, index) =>
                      <ItemAddress
                        alert={alert}
                        getMyAddresses={getMyAddresses}
                        setLoading={setLoading}
                        key={index}
                        id={item.id}
                        address={item.rua}
                        number={item.numero}
                        neighborhood={item.bairro}
                        city={item.cidade}
                        state={item.estado}
                        token={token}
                        zip_code={item.cep}
                        complement={item.complemento}
                      />
                    )
                  }
                  {loading &&
                    <>
                      <tr>
                        <td>
                          <Skeleton height="45px" width="100%" />
                        </td>
                        <td>
                          <Skeleton height="45px" width="100%" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Skeleton height="45px" width="100%" />
                        </td>
                        <td>
                          <Skeleton height="45px" width="100%" />
                        </td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>


            <hr className="pb-4" />
            <div className="text-sm-right">
              <a className="btn btn-primary" href="#add-address" data-toggle="modal">
                Adicionar novo endereço
              </a>
            </div>
          </section>
        </div>
      </div>

      <ModalAddress getMyAddresses={getMyAddresses} />
    </>
  );
}

export default inject('ClientStore')(observer(Addresses));