import React, { useState } from 'react';
import api from '../../services/api';
import AddressEdit from './AddressEdit';

const ItemAddress = ({
   address, number, neighborhood,
   city, state, id,
   token, alert, getMyAddresses,
   setLoading, zip_code, complement
  }) => {

  const [viewEdit, setViewEdit] = useState(false);

  const handleEdit = event => {
    event.preventDefault();

    setViewEdit(!viewEdit);
  }

  const deleteAddress = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await api.post('/endereco/delete', {
        id
      }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });

      if (!response.data.errors) {
        setLoading(false);
        getMyAddresses();
        alert.success('Endereço excluído com sucesso!');
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  return (
    <>
      <tr>
        <td className="py-3 align-middle">
          {`${address}, nº ${number}, ${neighborhood}, ${city}, ${state}`}
        </td>
        <td className="py-3 align-middle">
          <a className="nav-link-style mr-2" onClick={handleEdit} href="/" data-toggle="tooltip" title="Editar">
            <i className="czi-edit"></i>
          </a>
          <a className="nav-link-style text-danger" onClick={deleteAddress} href="/" data-toggle="tooltip" title="Excluir">
            <div className="czi-trash"></div>
          </a>
        </td>
      </tr>
      {viewEdit &&
        <tr>
          <td colSpan="2" className="overflow-hidden">
            <AddressEdit
              address={address}
              city={city}
              complement={complement}
              neighborhood={neighborhood}
              number={number}
              state={state}
              zip_code={zip_code}
              id={id}
              setViewEdit={setViewEdit}
              alert={alert}
              getMyAddresses={getMyAddresses}
            />
          </td>
        </tr>
      }
    </>
  );
}

export default ItemAddress;