import React from 'react';

const ModalProduct = ({ data }) => {
  return(
    <div className="d-sm-flex justify-content-between my-4 pb-3 pb-sm-2 border-bottom">
      <div className="media d-block d-sm-flex text-center text-sm-left">
        <a className="d-inline-block mx-auto mr-sm-4" href="shop-single-v1.html" style={{width: '10rem'}}>
          <img style={{maxHeight: 100}} src={data.url_fotos[0]} alt="Product" />
        </a>
        <div className="media-body pt-2">
          <h3 className="product-title font-size-base mb-2">
            <a href="shop-single-v1.html">
              { data.nome }
            </a>
          </h3>
          <div className="font-size-sm">
            {/* <span className="text-muted mr-2">Marca:</span> */}
            {/* { data.marca.nome } */}
          </div>
          <div className="font-size-lg text-accent pt-2">
            R$ { data.preco }
          </div>
        </div>
      </div>
      <div className="pt-2 pl-sm-3 mx-auto mx-sm-0 text-center">
        <div className="text-muted mb-2">Quantidade:</div> { data.quantidade }
      </div>
      <div className="pt-2 pl-sm-3 mx-auto mx-sm-0 text-center">
        <div className="text-muted mb-2">Subtotal</div>R$ { data.preco * data.quantidade }
      </div>
    </div>
  );
}

export default ModalProduct;