import React from 'react';
import { Link } from 'react-router-dom';

import CartItem from '../CartItem';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../../services/helpers';


const PageContent = (props) => {
  const { produtos, getTotal } = props.CarrinhoStore;
  const { observacao, setObservacao } = props.PedidoStore;

  return (
    <div className="container pb-5 mb-2 mb-md-4">
      <div className="row">
        <section className="col-lg-8">
          <div className="d-flex justify-content-between align-items-center pt-3 pb-2 pb-sm-5 mt-1">
            {/* <h2 className="h6 text-light mb-0 text-dark">Produtos</h2><a className="btn btn-outline-primary btn-sm pl-2" href="/"><i className="czi-arrow-left mr-2"></i>
              Continuar comprando
            </a> */}
          </div>

          {produtos.map((item, index) =>
            <CartItem key={item.id} index={index} item={item} />
          )}

          {(produtos.length == 0) &&
            <div className="text-center">
               <br/><br/><i className="h2 text-body czi-basket"></i><br/>
              <span>Nenhum produto em seu carrinho.</span>
            </div>
          }

        </section>

        <aside className="col-lg-4 pt-4 pt-lg-0">
          <div className="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
            <div className="text-center mb-4 pb-3 border-bottom">
              <h2 className="h6 mb-3 pb-1">Subtotal</h2>
              <h3 className="font-weight-normal">R$ {formatNumber(getTotal)}</h3>
            </div>
            {/* <div className="form-group mb-4">
              <label className="mb-3" htmlFor="order-comments"><span className="badge badge-info font-size-xs mr-2">Nota</span><span className="font-weight-medium">Adicionar comentário</span></label>
              <textarea value={observacao} onChange={(e) => setObservacao(e.target.value)} className="form-control" rows="6" id="order-comments"></textarea>
            </div> */}

            <Link className="btn btn-danger btn-shadow btn-block mt-4" to="/checkout-detalhes">
              <i className="czi-card font-size-lg mr-2"></i>
              Ir para pagamento
            </Link>
            <Link className="btn btn-shadow btn-block mt-4"  style={{backgroundColor: '#CAE5DA'}} to="/">
              <i className="czi-arrow-left font-size-lg mr-2"></i>
              Continuar comprando
            </Link>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default inject("CarrinhoStore", "PedidoStore")(observer(PageContent));