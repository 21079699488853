import React, { useState, useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import CardCombineProduct from '../components/Checkout/CardCombineProduct';
import api from '../services/api';
import { useParams } from "react-router-dom";
import loadjs from 'loadjs';
import { formatNumber } from '../services/helpers';
import Skeleton from 'react-loading-skeleton';
import ButtonLoading from '../components/ButtonLoading';
import InputMask from 'react-input-mask';
import { inject, observer } from 'mobx-react';
import { useAlert } from 'react-alert';

const SingleProduct = (props) => {
  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState({ marca: {}, url_fotos: [] });
  const [cep, setCep] = useState('');
  const [quantidade, setQuantidade] = useState(1);
  const [fretes, setFretes] = useState([]);
  const [fotos, setFotos] = useState([]);
  const { id } = useParams();
  const { setProdutoCarrinho } = props.CarrinhoStore;
  const [cor, setCor] = useState(null);
  const [estampa, setEstampa] = useState(null);
  const [personalizado, setPersonalizado] = useState(null);
  const [corString, setCorString] = useState(null);
  const [tamanho, setTamanho] = useState(null);
  const [tamanhoString, setTamanhoString] = useState(null);
  const [keyCor, setKeyCor] = useState(null);
  const { setFavorito } = props.ProdutoStore;
  const alert = useAlert();
  const { token } = props.ClientStore;

  async function getProduto() {
    try {
      setLoading(true);
      let response = await api.get('/produto/' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });

      if (response.data.data) {
        setProduto(response.data.data);
        setFotos(response.data.data.url_fotos)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const initialConfig = async () => {
    await getProduto();
    loadjs('/js/theme.min.js');
  }

  async function getFretes() {
    try {
      if (cep == '') {
        return;
      }

      setLoading(true);
      let response = await api.post('/frete', {
        produto_id: produto.id,
        cep_destino: cep
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });

      if (response.data.data) {
        if (produto.loja.entrega_gratis == 1) {
          setFretes([{
            "codigo": 3,
            "valor": 0.00,
            "prazo": "Entrega grátis",
            "tipo": "Entrega grátis"
          }]);
        } else {
          setFretes(response.data.data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    initialConfig();
    window.scrollTo(0, 0)
  }, [])

  function adicionarCarrinho() {
    if (produto.estoque_tamanho.length > 0) {
      if (!cor || !tamanho) {
        alert.show('Selecine a cor e tamanho.', { type: 'info' });
        return;
      }
    }

    if (produto.estampas_data.length > 0) {
      if (!estampa) {
        alert.show('Selecine uma estampa.', { type: 'info' });
        return;
      }
    }

    if (produto.personalizado == '1') {
      if (!personalizado) {
        alert.show('Preencha o campo personalizado.', { type: 'info' });
        return;
      }
    }

    setProdutoCarrinho(produto, quantidade, cor, tamanho, corString, tamanhoString, estampa, personalizado);
    alert.show('Produto adicionado com sucesso.', { type: 'success' });
  }

  function selectEstampa(index) {
    setFotos([produto.url_fotos[index]]);
  }


  return (
    <>
      <PageTitle nameRoute={produto.nome} url={'produto/' + produto.id} title={produto.nome} />
      <div className="container">
        <div className="bg-light box-shadow-lg rounded-lg">

          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item"><a className="nav-link p-4 active" href="#general" data-toggle="tab" role="tab">Informações gerais</a></li>
          </ul>
          <div className="px-4 pt-lg-3 pb-3 mb-5">
            <div className="tab-content px-lg-3">

              <div className="tab-pane fade show active" id="general" role="tabpanel">
                <div className="row">
                  <div className="col-lg-7 pr-lg-0">
                    {fotos[0] ?

                      <div className="cz-product-gallery">
                        {Object.keys(produto).length &&
                          <div className="cz-preview order-sm-2">
                            {fotos.map((foto, keyFoto) =>
                              !keyFoto ?
                                <div key={keyFoto} className="cz-preview-item active" id={'foto' + keyFoto}><img style={{ maxWidth: 600 }} className="cz-image-zoom" src={foto} data-zoom={foto} alt="" />
                                  <div className="cz-image-zoom-pane"></div>
                                </div>
                                :
                                <div key={keyFoto} className="cz-preview-item" id={'foto' + keyFoto}><img style={{ maxWidth: 600 }} className="cz-image-zoom" src={foto} data-zoom={foto} alt="" />
                                  <div className="cz-image-zoom-pane"></div>
                                </div>
                            )}
                          </div>
                        }

                        {Object.keys(produto).length &&
                          <div className="cz-thumblist order-sm-1">
                            {fotos.map((foto, keyFoto) =>
                              !keyFoto ?
                                <a key={keyFoto} className="cz-thumblist-item active" href={"#foto" + keyFoto}>
                                  <img src={foto} alt="Product thumb" />
                                </a>
                                :
                                <a key={keyFoto} className="cz-thumblist-item" href={"#foto" + keyFoto}>
                                  <img src={foto} alt="Product thumb" />
                                </a>
                            )}
                          </div>
                        }
                      </div>

                      :

                      <Skeleton height={200} width='100%' />
                    }
                  </div>

                  <div className="col-lg-5 pt-4 pt-lg-0">

                    <div className="product-details ml-auto pb-3">
                      <h5 className="mb-0">
                        {produto.nome}
                      </h5>
                      <br/>
                      <div className="h5 font-weight-normal text-accent mb-3 mr-1">
                        {produto.preco ? 'R$' + formatNumber(produto.desconto.length ? produto.preco_final : produto.preco) : <Skeleton height={30} width='70%' />}
                        {(produto.preco_final && produto.desconto.length > 0) && <><del className="text-muted font-size-lg mr-3"> {formatNumber(produto.preco)}</del></>}
                      </div>

                      {/* <div className="font-size-sm mb-4"><span className="text-heading font-weight-medium mr-1">Marca:</span><span className="text-muted">{produto.marca.nome ? produto.marca.nome : <Skeleton height={15} width='65%' />}</span></div> */}

                      {produto.estoque_tamanho &&
                        <>
                          <div className="position-relative mr-n4 mb-3">
                            {produto.estoque_tamanho.map((item, key) =>
                              <div className="custom-control custom-option custom-control-inline mb-2" key={item.cor_id}>
                                <input className="custom-control-input" type="radio" name="color" id={item.cor_id} value={item.cor_id} onChange={(e) => { setCor(e.target.value); setKeyCor(key); setCorString(item.cor_nome); setTamanho(null) }} checked={cor == item.cor_id ? true : false} disabled={item.cor_estoque > 0 ? false : true} />
                                <label className="custom-option-label rounded-circle" htmlFor={item.cor_id}><span className="custom-option-color rounded-circle" style={{ backgroundColor: item.cor_hex }}></span></label>
                              </div>
                            )}
                          </div>
                          {cor &&
                            <div className="form-group">
                              <div className="d-flex justify-content-between align-items-center pb-1">
                                <label className="font-weight-medium" htmlFor="product-size">Tamanho:</label>
                              </div>
                              <select onChange={(e) => { setTamanho(e.target.value); setTamanhoString(e.target.selectedOptions[0].getAttribute("tamanho")) }} className="custom-select" required id="product-size">
                                <option value="">Selecione</option>
                                {produto.estoque_tamanho[keyCor] &&
                                  produto.estoque_tamanho[keyCor].tamanhos.map((item) =>
                                    <option tamanho={item.nome} value={item.id} key={item.id} disabled={item.estoque > 0 ? false : true}>{item.estoque > 0 ? item.nome : item.nome + ' -- ESGOTADO'}</option>
                                  )
                                }
                              </select>
                            </div>
                          }

                        </>
                      }

                      {(produto.estampas_data && produto.estampas_data.length > 0) &&
                        <>
                          <div className="d-flex justify-content-between align-items-center pb-1">
                            <label className="font-weight-medium" htmlFor="product-size">Estampas: {estampa}</label>
                          </div>
                          <div className="position-relative mr-n4 mb-3">
                            {produto.estampas_data.map((item, key) =>
                              <div className="custom-control custom-option custom-control-inline mb-2" key={item.id}>
                                <input className="custom-control-input" type="radio" name="color" id={item.id} value={item.titulo} onChange={(e) => setEstampa(e.target.value)} checked={estampa == item.titulo ? true : false} />
                                <label className="custom-option-label rounded-circle" style={{ width: 50, height: 50, padding: 5 }} htmlFor={item.titulo}>
                                  <div onClick={() => { setEstampa(item.titulo); selectEstampa(key) }} className="rounded-circle" style={{ background: `url(${item.url_foto}) no-repeat center top / cover`, height: '100%' }}></div>
                                </label>
                              </div>
                            )}
                          </div>
                        </>
                      }

                      {produto.personalizado == '1' &&
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center pb-1">
                            <label className="font-weight-medium" htmlFor="product-size">Espaço para personalização (8,5x2cm aproximadamente):</label>
                          </div>
                          <input type="text" onChange={(e) => setPersonalizado(e.target.value)} className="form-control prepended-form-control" />
                        </div>
                      }

                      {produto.preco ?
                        <>
                          <div className="d-flex align-items-center pt-2 pb-4">
                            <select className="custom-select mr-3" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} style={{ width: '5rem' }}>
                              {
                                (produto.qtd_estoque > 0) ?
                                  Array(produto.qtd_estoque <= 20 ? parseInt(produto.qtd_estoque) : 20).fill().map((item, key) =>
                                    <option key={key} value={key + 1}>{key + 1}</option>
                                  )
                                  :
                                  <option value={0}>0</option>
                              }
                            </select>
                            <button disabled={produto.qtd_estoque > 0 ? false : true} onClick={() => adicionarCarrinho()} className="btn btn-danger btn-shadow btn-block" type="button"><i className="czi-cart font-size-lg mr-2"></i>{produto.qtd_estoque > 0 ? 'Adicionar' : 'Sem estoque'}</button>
                          </div>
                          <div className="d-flex mb-4">
                            <div className="w-100 mr-3">
                              <button className="btn btn-secondary btn-block" type="button" onClick={() => setFavorito(produto)}><i className="czi-heart font-size-lg mr-2"></i><span className='d-none d-sm-inline'>Adicionar aos </span>Favoritos</button>
                            </div>
                          </div>

                          {/* {produto.loja.taxa_entrega == 1 &&
                            <div className="accordion mb-4" id="productPanels">
                              <div className="card">
                                <div className="card-header">
                                  <h3 className="accordion-heading"><a href="#shippingOptions" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shippingOptions"><i className="czi-delivery text-muted lead align-middle mt-n1 mr-2"></i>Frete<span className="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
                                </div>
                                <div className="collapse show" id="shippingOptions" data-parent="#productPanels">
                                  <div className="card-body font-size-sm">

                                    {fretes.map((fret) =>
                                      <div key={fret.codigo} className="d-flex justify-content-between border-bottom pb-2">
                                        <div>
                                          <div className="font-weight-semibold text-dark">{fret.prazo}</div>
                                        </div>
                                        <div>R${formatNumber(fret.valor)}</div>
                                      </div>
                                    )}


                                    <form className="needs-validation" noValidate>
                                      <div className="form-group">
                                        <InputMask className="form-control" mask="99999-999" maskChar={null} value={cep} onChange={(e) => setCep(e.target.value)} placeholder="CEP" />
                                        <div className="invalid-feedback">
                                          Por favor, adicione um CEP válido.
                                      </div>
                                      </div>
                                      <ButtonLoading class="btn btn-outline-primary btn-block" onClick={() => getFretes()} loading={loading} title="Calcular Frete" />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          } */}
                        </>
                        :
                        <Skeleton height={100} width='100%' />
                      }



                      <h6 className="d-inline-block align-middle font-size-base my-2 mr-2"></h6><a className="share-btn sb-twitter mr-2 my-2" href="/"><i className="czi-twitter"></i>Twitter</a><a className="share-btn sb-instagram mr-2 my-2" href="/"><i className="czi-instagram"></i>Instagram</a><a className="share-btn sb-facebook my-2" href="/"><i className="czi-facebook"></i>Facebook</a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-3 pb-4 pb-sm-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {produto.descricao ? <div dangerouslySetInnerHTML={{ __html: produto.descricao }}></div> : <><Skeleton height={30} width='70%' /> <Skeleton height={30} width='65%' /><Skeleton height={30} width='80%' /></>}
          </div>
        </div>
      </div>

      <hr className="pb-5" />

      {(produto.produtos_similares && produto.produtos_similares.length > 0) &&
        <div className="container pt-lg-2 pb-5 mb-md-3">
          <p className="text-center pb-4">
            Produtos que você pode gostar
          </p>
          <div className="cz-carousel cz-controls-static cz-controls-outside">
            <div className="cz-carousel-inner" data-carousel-options="{&quot;items&quot;: 2, &quot;controls&quot;: true, &quot;nav&quot;: false, &quot;autoHeight&quot;: true, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;500&quot;:{&quot;items&quot;:2, &quot;gutter&quot;: 18},&quot;768&quot;:{&quot;items&quot;:3, &quot;gutter&quot;: 20}, &quot;1100&quot;:{&quot;items&quot;:4, &quot;gutter&quot;: 30}}}">


              {produto.produtos_similares.map((item) =>
                <div key={item.id}>
                  <CardCombineProduct item={item} />
                </div>
              )}

            </div>
          </div>
        </div>
      }

    </>
  );
}


export default inject("CarrinhoStore", "ProdutoStore", "ClientStore")(observer(SingleProduct));