import React from 'react';
import { Link } from 'react-router-dom';

const Step = ({ numberCurrent }) => {
  return (
    <div className="steps steps-light pt-2 pb-3 mb-5">
      <Link className={`step-item ${ numberCurrent >= 1 ? 'active' : '' } ${ numberCurrent === 1 ? 'current' : '' }`} to="/carrinho">
        <div className="step-progress">
          <span className="step-count">1</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-cart"></i>
          Carrinho
        </div>
      </Link>

      <Link className={`step-item ${ numberCurrent >= 2 ? 'active' : '' } ${ numberCurrent === 2 ? 'current' : '' }`} to="/checkout-detalhes">
        <div className="step-progress">
          <span className="step-count">2</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-user-circle"></i>
          Suas informações
        </div>
      </Link>
      
      <Link className={`step-item ${ numberCurrent >= 3 ? 'active' : '' } ${ numberCurrent === 3 ? 'current' : '' }`} to="/checkout-endereco">
        <div className="step-progress">
          <span className="step-count">3</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-package"></i>
          Entrega
        </div>
      </Link>
      
      {/* <Link className={`step-item ${ numberCurrent >= 4 ? 'active' : '' } ${ numberCurrent === 4 ? 'current' : '' }`} to="/checkout-pagamento">
        <div className="step-progress">
          <span className="step-count">4</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-card"></i>
          Pagamento
        </div>
      </Link> */}
      
      <Link className={`step-item ${ numberCurrent >= 4 ? 'active' : '' } ${ numberCurrent === 4 ? 'current' : '' }`} to="/checkout-conclusao">
        <div className="step-progress">
          <span className="step-count">4</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-check-circle"></i>
          Concluir
        </div>
      </Link>
    </div>
  );
}

export default Step;