import React from "react";
import Skeleton from "react-loading-skeleton";

const CardBanner = (props) => {
  return (
    <section className="container position-relative pt-3 pt-lg-0 pb-5 mt-lg-n5 d-none d-sm-block">
      <div className="row">
        <div className="col-xl-8 col-lg-9">
          <div className="card border-0 box-shadow-lg">
            <div className="card-body px-3 pt-grid-gutter pb-0">
              <div className="row no-gutters pl-1">
                {props.data.map((item) => (
                  <div key={item.id} className="col-sm-4 px-2 mb-grid-gutter">
                    <a
                      className="d-block text-center text-decoration-none mr-1"
                      href={item.link}
                    >
                      <img
                        style={{ margin: "0 auto", maxWidth: 150 }}
                        className="d-block rounded mb-3"
                        src={item.url_fotos}
                        alt="Men"
                      />
                      <h5 className="font-size-base pt-1 mb-0">
                        {item.titulo}
                      </h5>
                    </a>
                  </div>
                ))}

                {!props.data.length && (
                  <>
                    <div className="col-sm-4 px-2 mb-grid-gutter d-none d-sm-block">
                      <Skeleton height={200} />
                    </div>
                    <div className="col-sm-4 px-2 mb-grid-gutter d-none d-sm-block">
                      <Skeleton height={200} />
                    </div>
                    <div className="col-sm-4 px-2 mb-grid-gutter d-none d-sm-block">
                      <Skeleton height={200} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardBanner;
