import React, { useEffect, useState } from "react";
import CardProduct from "../components/CardProduct";
import Carousel from "../components/Carousel";
import CardBanner from "../components/CardBanner";
import Skeleton from "react-loading-skeleton";
import TitleCardProduct from "../components/TitleCardProduct";
import ButtonLoading from "../components/ButtonLoading";
import BannerMeio from "../components/BannerMeio";
import CarouselCategory from "../components/CarouselCategory";
import config from "../config";
import api from "../services/api";
import loadjs from "loadjs";
import { useAlert } from "react-alert";
import { observer, inject } from "mobx-react";
import { getAccessSession, setAccessSession } from "../services/Session";

const Home = (props) => {
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [miniBanners, setMiniBanners] = useState([]);
  const [category, setCategory] = useState({ produtos: [] });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { token } = props.ClientStore;

  const getProduct = async () => {
    setLoading(true);
    const response = await api.get(
      "/loja/paginate/" + config.loja_id + "?page=" + page + "&categoria=",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    setLoading(false);

    if (response.data.data) {
      setProducts(response.data.data.produtos.data);
    }
  };

  const getBanners = async () => {
    const response = await api.get("/banners?loja_id=" + config.loja_id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.data) {
      setBanners(response.data.data.banners);
      setMiniBanners(response.data.data.minibanners);
    }
  };

  const getCategory = async () => {
    const response = await api.get(
      "categoria/destaque?loja_id=" + config.loja_id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.data.data) {
      setCategory(response.data.data);
    }
  };

  async function getPaginate() {
    try {
      setLoading(true);
      let response = await api.get(
        "/loja/paginate/" +
          config.loja_id +
          "?page=" +
          (page + 1) +
          "&categoria=",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.data) {
        setProducts([...products, ...response.data.data.produtos.data]);
      }
      setPage(page + 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.show("Ocorreu um erro, verifique sua conexão.", { type: "error" });
    }
  }

  const initialConfig = async () => {
    await getBanners();
    loadjs("/js/theme.min.js");
    await getProduct();
    await getCategory();
    loadjs("/js/theme.min.js");
  };

  useEffect(() => {
    initialConfig();
  }, []);

  return (
    <>
      {banners.length ? <Carousel data={banners} /> : <Skeleton height={300} />}

      <CardBanner data={miniBanners} />

      <TitleCardProduct title=" ">
        {products.length ? (
          products.map((item) => <CardProduct key={item.id} product={item} />)
        ) : (
          <>
            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>
          </>
        )}
      </TitleCardProduct>

      <div className="container pt-md-3 pb-5 mb-md-3">
        <div className="text-center">
          <ButtonLoading
            class="btn btn-danger"
            onClick={() => getPaginate()}
            loading={loading}
            title="Mais produtos"
          />
        </div>
      </div>

      {/* <BannerMeio /> */}

      {category.produtos.length > 0 && <CarouselCategory data={category} />}

      {/* <ModalHome /> */}
    </>
  );
};

export default inject("ClientStore")(observer(Home));
