import React from 'react';

const OrderItem = ({ idOrder, dateOrder, status, value, data, setDataModal, cor, setReclamar }) => {
  
  const handleDataModal = () => {
    setDataModal(data);
  }

  return (
    <>
    <tr>
      <td className="py-3">
        <a className="nav-link-style font-weight-medium font-size-sm" href="#order-details" data-toggle="modal" onClick={handleDataModal}>
          #{ idOrder }
        </a>
      </td>
      <td className="py-3">
        { dateOrder }
      </td>
      <td className="py-3" style={{color: cor}}>
        { status }
      </td>
      <td className="py-3">
        R$ { value }
      </td>
      <td className="py-3">
        <a href="#order-reclamar" data-toggle="modal" onClick={setReclamar} class="btn btn-primary">Relatar problema</a>
      </td>
    </tr>
    </>
  );
}

export default OrderItem;