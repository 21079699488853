import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import ButtonLoading from '../components/ButtonLoading';
import { formatNumber } from '../services/helpers';
import api from '../services/api';
import { IsEmail } from '../services/helpers';
import { useAlert } from 'react-alert';

const Footer = (props) => {
    const { getTotal, produtos } = props.CarrinhoStore;
    const { menus } = props.HeaderStore;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const alert = useAlert();

    const newsletter = async () => {
        if (email == '' || !IsEmail(email)) {
            alert.show('Preencha o campo com e-mail válido.', { type: 'info' });
            return;
        }
        setLoading(true);
        try {
            let response = await api.post('/newsletter', {
                email: email,
            });

            if (!response.data.errors) {
                setLoading(false);
                alert.show('E-mail cadastrado com sucesso.', { type: 'success' });
                setEmail("");
            } else {
                setLoading(false);
                alert.show('E-mail já cadastrado.', { type: 'info' });
                setEmail("");
            }
        } catch (error) {
            setLoading(false);
            alert.show('Ocorreu um erro desconhecido.', { type: 'error' });
        }

    }


    return (
        <footer className="pt-5">
            {/* <div className="container">
                <div className="row pb-2">
                    <div className="col-md-6 col-sm-6">
                        <div className="widget widget-links widget-light pb-2 mb-4">
                            <h3 className="widget-title text-primary">Departamentos da loja</h3>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <ul className="widget-list">
                                        {menus.categorias.map((item, key) =>
                                            key <= 8 &&
                                            <li key={item.slug} className="widget-list-item"><a className="widget-list-link" href={"/categoria/" + item.slug}>{item.nome}</a></li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ul className="widget-list">
                                        {menus.categorias.map((item, key) =>
                                            key > 6 &&
                                            <li key={item.slug} className="widget-list-item"><a className="widget-list-link" href={"/categoria/" + item.slug}>{item.nome}</a></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="widget widget-links widget-light pb-2 mb-4">
                            <h3 className="widget-title text-primary">Conta &amp; Informação de Envio</h3>
                            <ul className="widget-list">
                                <li className="widget-list-item"><a className="widget-list-link" href="/perfil">Sua conta</a></li>
                                <li className="widget-list-item"><a className="widget-list-link" href="/enderecos">Endereços</a></li>
                                <li className="widget-list-item"><a className="widget-list-link" href="/favoritos">Favoritos</a></li>
                                <li className="widget-list-item"><a className="widget-list-link" href="/pedidos">Pedidos</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="widget pb-2 mb-4">
                            <h3 className="widget-title text-primary pb-1">Mantenha-se informado</h3>
                            <form className="validate">
                                <div className="input-group input-group-overlay flex-nowrap">
                                    <div className="input-group-prepend-overlay"><span className="input-group-text text-muted font-size-base"><i className="czi-mail"></i></span></div>
                                    <input onChange={(e) => setEmail(e.target.value)} value={email} className="form-control prepended-form-control" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Seu e-mail" required />
                                    <div className="input-group-append">
                                        <ButtonLoading class="btn btn-primary" onClick={() => newsletter()} loading={loading} title="Se inscrever" />
                                    </div>
                                </div>
                                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                                    <input type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabIndex="-1" />
                                </div><small className="form-text text-light opacity-50" id="mc-helper">* Assine nossa newsletter para receber ofertas de descontos antecipados, atualizações e informações sobre novos produtos.</small>
                                <div className="subscribe-status"></div>
                            </form>
                        </div>
                        <div className="widget pb-2 mb-4">
                            <h3 className="widget-title text-primary pb-1">Download nosso app</h3>
                            <div className="d-flex flex-wrap">
                                <div className="mr-2 mb-2"><a className="btn-market btn-apple" href="# " role="button"><span className="btn-market-subtitle">Download em</span><span className="btn-market-title">App Store</span></a></div>
                                <div className="mb-2"><a className="btn-market btn-google" href="# " role="button"><span className="btn-market-subtitle">Download em</span><span className="btn-market-title">Google Play</span></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="pt-5" style={{ backgroundColor: "#fbfbfb" }}>
                <div className="container">
                    {/* <div className="row pb-3">
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="media"><i className="czi-rocket text-primary" style={{ fontSize: "2.25rem" }}></i>
                                <div className="media-body pl-3">
                                    <h6 className="font-size-base text-light mb-1">Entrega rápida e gratuita</h6>
                                    <p className="mb-0 font-size-ms text-light opacity-50">Entrega gratuita para todas as encomendas superiores a R$ 200</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="media"><i className="czi-currency-exchange text-primary" style={{ fontSize: "2.25rem" }}></i>
                                <div className="media-body pl-3">
                                    <h6 className="font-size-base text-light mb-1">Garantia de devolução de dinheiro</h6>
                                    <p className="mb-0 font-size-ms text-light opacity-50">Devolvemos dinheiro dentro de 30 dias</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="media"><i className="czi-support text-primary" style={{ fontSize: "2.25rem" }}></i>
                                <div className="media-body pl-3">
                                    <h6 className="font-size-base text-light mb-1">Suporte ao cliente 24/7</h6>
                                    <p className="mb-0 font-size-ms text-light opacity-50">Suporte ao cliente amigável 24/7</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <div className="media"><i className="czi-card text-primary" style={{ fontSize: "2.25rem" }}></i>
                                <div className="media-body pl-3">
                                    <h6 className="font-size-base text-light mb-1">Pagamento online seguro</h6>
                                    <p className="mb-0 font-size-ms text-light opacity-50">Possuímos certificado SSL / Secure</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-light pb-4 mb-3" /> */}
                    <div className="row pb-2">
                        <div className="col-md-6 text-center text-md-left mb-4">
                            <div className="text-nowrap mb-4"><a className="d-inline-block align-middle mt-n1 mr-3" href="# "><img className="d-block" width="117" src="/img/footer-logo-light.png" alt="Cartzilla" /></a>
                            </div>
                            <div className="widget widget-links">
                                <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                                    {menus.paginas.map((item) =>
                                        (item.categoria == 'rodape') &&
                                        item.paginas.map((pagina) =>
                                            <li key={pagina.slug} className="widget-list-item mr-4"><a className="widget-list-link" href={"/detalhe/" + pagina.slug}>{pagina.nome}</a></li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 text-center text-md-right mb-4">
                            <div className="mb-3">
                                <a className="social-btn sb-outline sb-twitter ml-2 mb-2" href="# ">
                                    <i className="czi-twitter"></i>
                                </a>
                                <a className="social-btn sb-outline sb-facebook ml-2 mb-2" href="#">
                                    <i className="czi-facebook"></i>
                                </a>
                                <a className="social-btn sb-outline sb-instagram ml-2 mb-2" href="# ">
                                    <i className="czi-instagram"></i>
                                </a>
                                <a className="social-btn sb-outline sb-pinterest ml-2 mb-2" href="# ">
                                    <i className="czi-pinterest"></i></a><a className="social-btn sb-outline sb-youtube ml-2 mb-2" href="# ">
                                    <i className="czi-youtube"></i>
                                </a>
                            </div>
                            <img className="d-inline-block" width="187" src="/img/cards-alt.svg" alt="Payment methods" />
                        </div>
                    </div>
                    <span>CNPJ: 17.940.670/0001-9</span>
                    <div className="pb-4 font-size-xs opacity-50 text-center text-md-left">© Todos os direitos reservados.</div>
                </div>
            </div>

            <div className="cz-handheld-toolbar">
                <div className="d-table table-fixed w-100"><a className="d-table-cell cz-handheld-toolbar-item" href="/favoritos">
                    <span className="cz-handheld-toolbar-icon"><i className="czi-heart"></i></span><span className="cz-handheld-toolbar-label">Favoritos</span></a>
                    <a className="d-table-cell cz-handheld-toolbar-item" href="#navbarCollapse" data-toggle="collapse" onClick={() => window.scrollTo(0, 0)}><span className="cz-handheld-toolbar-icon"><i className="czi-menu"></i></span><span className="cz-handheld-toolbar-label">Menu</span></a>
                    <a className="d-table-cell cz-handheld-toolbar-item" href="/carrinho"><span className="cz-handheld-toolbar-icon"><i className="czi-cart"></i><span className="badge badge-primary badge-pill ml-1">{produtos.length}</span></span><span className="cz-handheld-toolbar-label">R$ {formatNumber(getTotal)}</span></a>
                </div>
            </div>
        </footer>
    );
}

export default inject("CarrinhoStore", "HeaderStore")(observer(Footer));