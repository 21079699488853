import React from 'react';
import { formatNumber } from '../../services/helpers';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';


const CardCombineProduct = (props) => {
  const { setFavorito } = props.ProdutoStore;
  return (
    <div className="card product-card card-static text-center">
      {props.item.qtd_estoque <= 0 &&
        <span className="badge badge-danger badge-shadow p-2">Esgotado</span>
      }
      <button className="btn-wishlist btn-sm" type="button" onClick={() => setFavorito(props.item)} data-toggle="tooltip" data-placement="left" title="Adicionar aos favoritos"><i className="czi-heart"></i></button>
      <a className="card-img-top d-block overflow-hidden text-center" href={'/produto/' + props.item.id + '/' + props.item.slug}><img style={{ maxHeight: 200 }} src={props.item.url_fotos[0] && props.item.url_fotos[0]} alt="Product" /></a>
      <div className="card-body py-2"><a className="product-meta d-block font-size-xs pb-1" href={'/produto/' + props.item.id + '/' + props.item.slug}>{props.item.categorias[0] && props.item.categorias[0].nome}</a>
        <h3 className="product-title font-size-sm"><a href={'/produto/' + props.item.id + '/' + props.item.slug}>{props.item.nome}</a></h3>
        <div className="d-flex justify-content-between">
          <div className="product-price text-center" style={{ width: '100%' }}>R${props.item.desconto.length ? formatNumber(props.item.preco_final) : formatNumber(props.item.preco)}</div>
          {(props.item.desconto.length > 0) &&
            <del className="font-size-sm text-muted">R$ {formatNumber(props.item.preco)}</del>
          }
        </div>
      </div>
    </div>
  );
}

export default inject("ProdutoStore")(observer(CardCombineProduct));