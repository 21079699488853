import React from 'react';

const ButtonLoading = (props) => {
    return (
        <>
            {props.loading ?
                <button type="button" className={props.class}>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                </button>
                :
                <button type={props.type ? props.type : 'button' } onClick={props.onClick} className={props.class} data-toggle="button">{props.title}</button>
            }
        </>
    );
}

export default ButtonLoading;