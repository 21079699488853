import React from 'react';
import TitleCardProduct from '../components/TitleCardProduct';

const Categoria = (props) => {

    return (
        <>
            <TitleCardProduct>
                <div className="col-12 p-5 text-center">
                    <i className="h2 text-body czi-heart"></i>
                    <h2>Muito obrigado por comprar conosco!</h2>
                    <a className="bnt bnt-primary" href="/pedidos">Acompanhe seu pedido</a>
                </div>
            </TitleCardProduct>

        </>
    );
}

export default Categoria;