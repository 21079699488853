import React from 'react';

const TypeShipping = ({ name, description, term, price, checked, change, value, type }) => {
  return (
    <tr>
      <td>
        <div className="custom-control custom-radio mb-4">
          <input value={value} checked={checked} onChange={change} className="custom-control-input" type="radio" name={"shipping-method"} />
          <label onClick={change} className="custom-control-label" htmlFor={value}></label>
        </div>
      </td>
      <td className="align-middle">
         { type.toUpperCase() }
      </td>
      <td className="align-middle">
        <span className="text-dark font-weight-medium">
          { name }
        </span><br />
        <span className="text-muted">
          { description }
        </span>
      </td>
      <td className="align-middle">
        R$ { price }
      </td>
    </tr>
  );
}

export default TypeShipping;