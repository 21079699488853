import { decorate, observable, action } from "mobx";
import Cookies from 'js-cookie';

class ClientStore {

    token = null;
    dados = {};
    logado = false;

    setToken = async (token) => {
        this.token = token;
        await Cookies.set('token', token, {expires: 7});
        this.logado = true;
    }

    setDados = async (data)=>{
        this.dados = data;
        await Cookies.set('client', JSON.stringify(data), {expires: 7});
    }

    getToken = async () => {
        let token = await Cookies.get('token');
        if (token) {
            this.token = token;
            this.logado = true;
        } else {
            this.logado = false;
        }
    }

    getDados =  async () =>{
        let data = await Cookies.get('client');
        if (data) {
            this.dados = JSON.parse(data);
        }
    }

    logout = () => {
        Cookies.remove('client');
        Cookies.remove('token');
        this.token = "";
        this.dados = {};
        this.logado = false;
    }

}

decorate(ClientStore, {
    token: observable,
    dados: observable,
    logado: observable,
    setToken: action,
    setDados: action,
    getDados: action,
    getToken: action,
    logout: action
});

export default new ClientStore();