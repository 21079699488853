import React, { useEffect, useState } from 'react';
import CardProduct from '../components/CardProduct';
import Skeleton from 'react-loading-skeleton';
import TitleCardProduct from '../components/TitleCardProduct';
import { useParams } from "react-router-dom";
import api from '../services/api';
import config from '../config';
import { useAlert } from 'react-alert';
import {observer, inject} from 'mobx-react';

const Categoria = (props) => {
    const [products, setProducts] = useState([]);
    const [categoria, setCategoria] = useState({});
    const [buscou, setBuscou] = useState(false);
    const alert = useAlert();
    const { slug } = useParams();
    const {token} = props.ClientStore;

    const Buscar = async () => {
        try {
            const response = await api.get(`/produtos?cat=`+slug, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            setBuscou(true);
            if (response.data.data) {
                setCategoria(response.data.data.categoria)
                setProducts(response.data.data.produtos);
            } else {
                alert.show('Ocorreu um erro desconhecido.', { type: 'error' });
            }
        } catch (error) {
            alert.show('Verifique sua conexão!', { type: 'error' });
        }
    }

    useEffect(() => {
        Buscar();
    }, []);

    return (
        <>
            <TitleCardProduct title={categoria.nome}>
                {(products.length) ?
                    products.map((item) =>
                        <CardProduct key={item.id} product={item} />
                    )
                    :
                    !buscou ?
                        <>
                            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                <Skeleton height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                <Skeleton height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                <Skeleton height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                <Skeleton height={200} />
                            </div>
                        </>
                        :
                        <div className="col-12">
                            <div className="text-center">
                                <br /><br /><i className="h2 text-body czi-cart"></i><br />
                                <span>Nenhum produto encontrado.</span>
                            </div>
                        </div>
                }


            </TitleCardProduct>



        </>
    );
}

export default inject("ClientStore")(observer(Categoria));