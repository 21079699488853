import React from 'react';
import { formatNumber } from '../services/helpers';
import {inject, observer} from 'mobx-react';
import { Link } from 'react-router-dom';


const CardProductCategory = ({item, ProdutoStore}) => {
    const { setFavorito } = ProdutoStore;
    return (
        <div className="col-lg-4 col-6 px-0 px-sm-2 mb-sm-4">
            <div className="card product-card card-static">
                {item.qtd_estoque <= 0 &&
                    <span className="badge badge-danger badge-shadow p-2">Esgotado</span>
                }
                <button className="btn-wishlist btn-sm" onClick={() => setFavorito(item)} type="button" data-toggle="tooltip" data-placement="left" title="Adicionar aos favoritos"><i className="czi-heart"></i></button><Link className="card-img-top d-block overflow-hidden" to={"/produto/"+item.id+"/"+item.slug}><img src={item.url_fotos[0] && item.url_fotos[0]} alt="Product" /></Link>
                <div className="card-body py-2"><Link className="product-meta d-block font-size-xs pb-1" to={"/produto/"+item.id+"/"+item.slug}>{item.categorias[0] && item.categorias[0].nome}</Link>
                    <h3 className="product-title font-size-sm"><Link to={"/produto/"+item.id+"/"+item.slug}>{item.nome}</Link></h3>
                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="text-accent">R$ {item.desconto.length ? formatNumber(item.preco_final) : formatNumber(item.preco)}</span></div>
                        {(item.desconto.length > 0) &&
                         <del class="font-size-sm text-muted">R$ {formatNumber(item.preco)}</del>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default inject("ProdutoStore")(observer(CardProductCategory));