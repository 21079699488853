import React from 'react';
import { formatNumber } from '../services/helpers';
import { inject, observer } from 'mobx-react';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';


const CartItem = (props) => {
  const { increment, removeProduto } = props.CarrinhoStore;
  const alert = useAlert();

  return (
    <div className="d-sm-flex justify-content-between align-items-center my-4 pb-3 border-bottom">
      <div className="media media-ie-fix d-block d-sm-flex align-items-center text-center text-sm-left">
        <Link className="d-inline-block mx-auto mr-sm-4" to={"/produto/" + props.item.id + "/" + props.item.slug} style={{ width: '10rem' }}>
          <img src={props.item.url_fotos} alt="Product" />
        </Link>
        <div className="media-body pt-2">
          <h3 className="product-title font-size-base mb-2">
            <Link to={"/produto/" + props.item.id + "/" + props.item.slug}>
              {props.item.nome}
            </Link>
          </h3>
          <span>{props.item.estampa} {props.item.cor_string}/ {props.item.tamanho_string} {props.item.personalizado}</span>
          <div className="font-size-lg text-accent pt-2">
            R$ {props.item.desconto.length ? formatNumber(props.item.preco_final) : formatNumber(props.item.preco)}
          </div>
        </div>
      </div>
      <div className="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style={{ maxWidth: '9rem' }}>
        <div className="form-group mb-0">
          <label className="font-weight-medium" htmlFor="quantity1">
            Quantidade
          </label>
          <div className="row text-center d-flex align-items-center">
            <div className="col-3">
              <button onClick={() => increment(props.index, '-')} className="btn btn-link px-0 text-danger">-</button>
            </div>
            <div className="col-5">
              <label>{parseInt(props.item.quantidade)}</label>
            </div>
            <div className="col-3">
              <button onClick={() => increment(props.index, '+')} className="btn btn-link px-0 text-danger">+</button>
            </div>
          </div>
        </div>
        <button onClick={() => { removeProduto(props.index); alert.show('Produto removido com sucesso.', { type: 'success' }) }} className="btn btn-link px-0 text-danger" type="button"><i className="czi-close-circle mr-2">
        </i><span className="font-size-sm">Remover</span>
        </button>
      </div>
    </div>
  );
}

export default inject("CarrinhoStore")(observer(CartItem));