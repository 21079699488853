import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TitleCardProduct from '../components/TitleCardProduct';
import { useParams } from "react-router-dom";
import api from '../services/api';

import { useAlert } from 'react-alert';

const Page = (props) => {
    const [data, setData] = useState({});
    const alert = useAlert();
    const { slug } = useParams();

    const Buscar = async () => {
        try {
            const response = await api.get(`/pagina?slug=`+slug, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (response.data.data) {
                setData(response.data.data);
            } else {
                alert.show('Página não encontrada.', { type: 'error' });
            }
        } catch (error) {
            alert.show('Verifique sua conexão!', { type: 'error' });
        }
    }

    useEffect(() => {
        Buscar();
    }, []);

    return (
        <>
            <TitleCardProduct title={data.titulo}>
                {data.id ?
                   <div dangerouslySetInnerHTML={{ __html:data.conteudo}}></div>
                    :
                    <>
                        <div className="col-12">
                            <Skeleton height={200} />
                        </div>
                    </>
                }


            </TitleCardProduct>



        </>
    );
}

export default Page;