import React from 'react';
import { Link } from 'react-router-dom';

const CardCheckoutProduct = ({ title, image, price, amount, slug, id }) => {
  return (
    <div className="media align-items-center py-2 border-bottom">
      <Link className="d-block mr-2" to={'/produto/' + id + '/'+slug}>
        <img width="64" src={ image } alt={ title } />
      </Link>
      <div className="media-body">
        <h6 className="widget-product-title">
          <Link to={'/produto/' + id + '/'+slug}>
            { title }
          </Link>
        </h6>
        <div className="widget-product-meta">
          <span className="text-accent mr-2">R$ { price }</span>
          <span className="text-muted">x { amount }</span>
        </div>
      </div>
    </div>
  );
}

export default CardCheckoutProduct;