import { decorate, observable, action, computed } from "mobx";
import _ from 'lodash';
import Cookies from 'js-cookie';


class CarrinhoStore {

    produtos = [];
    objectFretes = [];
    freteSelecionado = {};
    cep = "";

    setFreteSelecionado = (freteSelecionado) => {
        this.freteSelecionado = freteSelecionado;
    }

    setCep = (cep) => {
        this.cep = cep;
    }

    setObjectFretes = (objectFretes) => {
        this.objectFretes = objectFretes;
    }

    setEmbalagem = async (key, texto, presente, embalagem_id, valor_extra) =>{
        let newProdutos = this.produtos;
        if(presente == 'nao'){
            newProdutos[key].presente = presente;
            newProdutos[key].texto_presente = '';
            newProdutos[key].embalagem_id = null;
            newProdutos[key].valor_extra = 0;
        }else{
            newProdutos[key].presente = presente;
            newProdutos[key].texto_presente = texto;
            newProdutos[key].embalagem_id = embalagem_id;
            newProdutos[key].valor_extra = valor_extra;
        }
        
        this.produtos = newProdutos;
        await Cookies.set('carrinho', JSON.stringify(newProdutos));
    }

    setProdutoCarrinho = async (produto, quantidade, cor, tamanho, corString, tamanhoString, estampa, personalizado) => {
        let newProdutos = this.produtos;
       // if (_.findIndex(this.produtos, function (o) { return o.id == produto.id; }) < 0) {
            newProdutos.push({
                id: produto.id,
                nome: produto.nome,
                preco: produto.preco,
                qtd_estoque: produto.qtd_estoque,
                quantidade: quantidade,
                url_fotos: produto.url_fotos[0] ? produto.url_fotos[0] : '',
                loja_id: produto.loja_id,
                loja: produto.loja.nome,
                taxa_entrega: produto.loja.taxa_entrega,
                entrega_gratis: produto.loja.entrega_gratis,
                pagamento_entrega: produto.loja.pagamento_entrega,
                pagamento_app: produto.loja.pagamento_app,
                desconto: produto.desconto,
                slug: produto.slug,
                tamanho: tamanho,
                cor: cor,
                cor_string: corString,
                valor_extra: 0,
                tamanho_string: tamanhoString,
                presente: 'nao',
                texto_presente: '',
                embalagem_id: null,
                estampa: estampa,
                personalizado:personalizado,
                preco_final: produto.preco_final
            });
            this.produtos = newProdutos;
            await Cookies.set('carrinho', JSON.stringify(newProdutos));
            window.location.href = "/carrinho";
        //}
        this.setFreteSelecionado([]);
        window.location.href = "/carrinho";
    }

    getProdutosCarrinho = () =>{
        let produtos = Cookies.get('carrinho') ? JSON.parse(Cookies.get('carrinho')) : [];
        this.produtos = produtos;
    }

    increment = (index, tipo) => {
        //let index = _.findIndex(this.produtos, function (o) { return o.id == id; });

        if (tipo == '+') {
            if (this.produtos[index].quantidade < this.produtos[index].qtd_estoque) {
                this.produtos[index].quantidade = this.produtos[index].quantidade + 1;
            }
        } else if (tipo == '-') {
            if (this.produtos[index].quantidade > 1) {
                this.produtos[index].quantidade = this.produtos[index].quantidade - 1;
            }
        }
        Cookies.set('carrinho', JSON.stringify(this.produtos));
        this.setFreteSelecionado([]);
    }

    removeProduto = (index) => {
        // let newProdutos = _.filter(this.produtos, function (o) {
        //     return o.id != id;
        // });

        let newProdutos = this.produtos;
        newProdutos.splice(index, 1);
        this.produtos = newProdutos;
        Cookies.set('carrinho', JSON.stringify(newProdutos));
    }

    get getTotal() {
        let total = 0;
        this.produtos.map((item) => {
            total += item.quantidade * (item.desconto.length ? item.preco_final : item.preco);
            total += item.valor_extra;
        });
        return total;
    }

    get getExtra() {
        let total = 0;
        this.produtos.map((item) => {
            total += item.valor_extra;
        });
        return total;
    }

    cleanCart = () =>{
        Cookies.remove('carrinho', { path: '' })
        this.produtos = [];
    }

}

decorate(CarrinhoStore, {
    produtos: observable,
    cep: observable,
    objectFretes: observable,
    freteSelecionado: observable,
    setProdutoCarrinho: action,
    setFreteSelecionado: action,
    setObjectFretes: action,
    setCep: action,
    cleanCart: action,
    setEmbalagem: action,
    getProdutosCarrinho: action,
    getTotal: computed,
    getExtra: computed
});

export default new CarrinhoStore();