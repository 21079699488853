import React from 'react';
import CardProductCategory from './CardProductCategory';


const CarouselCategory = (props) => {

    return (
        <section className="container mb-4 pb-3 pb-sm-0 mb-sm-5">
            <div className="row">
                <div className="col-md-5">
                    <div className="d-flex flex-column h-100 overflow-hidden rounded-lg" style={{ backgroundColor: '#F1F1F1' }}>
                        <div className="d-flex justify-content-between px-grid-gutter py-grid-gutter">
                            <div>
                                <h3 className="mb-1 text-primary">{props.data.categoria.nome}</h3>
                            </div>
                        </div><a className="d-none d-md-block mt-auto" href="# "><img className="d-block w-100" src={props.data.categoria.imagem_url} /></a>
                    </div>
                </div>

                <div className="col-md-7 pt-4 pt-md-0">
                    <div className="cz-carousel">
                        <div className="cz-carousel-inner" data-carousel-options="{&quot;nav&quot;: false}">

                            <div>
                                <div className="row mx-n2">
                                    {props.data.produtos.map((item) =>
                                        <CardProductCategory key={item.id} item={item} />
                                    )}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CarouselCategory;