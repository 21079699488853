import React, { useState } from 'react';
import CardCheckoutProduct from './CardCheckoutProduct';
import api from '../../services/api';
import config from '../../config';
import { useAlert } from 'react-alert';
import { inject, observer } from 'mobx-react';
import ButtonLoading from '../../components/ButtonLoading';
import { formatNumber } from '../../services/helpers';


const AsideCheckout = (props) => {
  const { produtos, getTotal, getExtra } = props.CarrinhoStore;
  const { cupom, setCupom, setDadosCupom, cupomDados, freteSelecionado, setParcelas } = props.PedidoStore;
  const { token } = props.ClientStore;
  const alert = useAlert();
  const [loading, setLoading] = useState(false);


  async function getCupom() {

    if (cupom == '') {
      alert.show('Preencha o campo cupom!', { type: 'error' });
      return;
    }

    setLoading(true);
    try {
      let response = await api.post('/cupom', {
        cupom: cupom,
        loja_id: config.loja_id,
        valor_pedido: getTotal
      }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      setLoading(false);
      if (!response.data.errors) {
        alert.show('Cupom adicionado com sucesso!', { type: 'success' });
        setDadosCupom(response.data.data);
        if (freteSelecionado) {
          getParcelas(freteSelecionado.valor - response.data.data.valor);
        }

      } else {
        setCupom('');
        setDadosCupom(null);
        alert.show(response.data.message, { type: 'info' });
      }
    } catch (error) {
      setCupom('');
      setDadosCupom(null);
      setLoading(false);
      alert.show('Verifique a sua conexão!', { type: 'error' });
    }
  }

  function getParcelas(frete) {
    let newArray = [];
    let parcelas = 12;
    for (let index = 1; index < parcelas + 1; index++) {
      newArray.push({
        label: index + 'x de R$ ' + formatNumber((frete + getTotal) / index),
        value: index
      })
    }
    setParcelas(newArray);
  }


  return (
    <aside className="col-lg-4 pt-4 pt-lg-0">
      <div className="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
        <div className="widget mb-3">
          <h2 className="widget-title text-center">
            Resumo do Pedido
          </h2>

          {produtos.map((item) =>
            <CardCheckoutProduct
              key={item.id}
              amount={item.quantidade}
              title={item.nome}
              price={formatNumber(item.desconto.length ? item.preco_final : item.preco)}
              slug={item.slug}
              id={item.id}
              image={item.url_fotos}
            />
          )}

        </div>
        <ul className="list-unstyled font-size-sm pb-2 border-bottom">

          <li className="d-flex justify-content-between align-items-center">
            <span className="mr-2">Frete:</span>
            <span className="text-right">
              R$ {freteSelecionado ? formatNumber(freteSelecionado.valor) : '0.00'}
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span className="mr-2">Desconto:</span>
            <span className="text-right">R$ {cupomDados ? formatNumber(cupomDados.valor) : '0.00'}</span>
          </li>
          {getExtra > 0 &&
            <li className="d-flex justify-content-between align-items-center">
              <span className="mr-2">Valores extra:</span>
              <span className="text-right">R$ {formatNumber(getExtra)}</span>
            </li>
          }
          <li className="d-flex justify-content-between align-items-center">
            <span className="mr-2">Subtotal:</span>
            <span className="text-right">
              R$ {formatNumber(getTotal)}
            </span>
          </li>
        </ul>
        <h3 className="font-weight-normal text-center my-4">
          R$ {freteSelecionado ? formatNumber(getTotal + parseFloat(freteSelecionado.valor) - (cupomDados ? parseFloat(cupomDados.valor) : 0)) : formatNumber(getTotal - (cupomDados ? parseFloat(cupomDados.valor) : 0))}
        </h3>
        <form className="needs-validation" noValidate>
          <div className="form-group">
            <input value={cupom} onChange={(e) => setCupom(e.target.value)} className="form-control" type="text" placeholder="Cupom de Desconto" required />
            <div className="invalid-feedback">
              Por favor, digite um cupom válido.
            </div>
          </div>
          <ButtonLoading class="btn btn-outline-primary btn-block" onClick={() => getCupom()} loading={loading} title="Aplicar Cupom" />
        </form>
      </div>
    </aside>
  );
}

export default inject("PedidoStore", "CarrinhoStore", "ClientStore")(observer(AsideCheckout));