import React, { useState } from 'react';
import ModalProduct from './ModalProduct';

const data = {
  image: 'img/shop/cart/04.jpg',
  title: 'Produto de teste',
  brand: 'Qualquer',
  price: '10.00',
  quantity: '1',
  subtotal: '10.00'
};

const ModalOrders = ({ dataOrder }) => {
  return(
    <div className="modal fade" id="order-details">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Nº do Pedido: { dataOrder.id }
            </h5>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body pb-0">
            {/* <!-- Items--> */}
            {dataOrder.produtos &&
              dataOrder.produtos.map((item, index) => <ModalProduct key={index} data={item} />)
            }
          </div>
          {/* <!-- Footer--> */}
          <div className="modal-footer flex-wrap justify-content-between bg-secondary font-size-md">
            <div className="px-2 py-1">
              <span className="text-muted">
              Subtotal: R$ { dataOrder.valor }
              </span>
            </div>
            <div className="px-2 py-1">
              <span className="text-muted">
                Frete: R$ { dataOrder.valor_frete }
              </span>
            </div>
            <div className="px-2 py-1">
              <span className="text-muted">
                Desconto: R$ { dataOrder.valor_cupom ? dataOrder.valor_cupom : '0,00' }
              </span>
              </div>
            <div className="px-2 py-1">
              <span className="text-muted">
                Total: R$ { dataOrder.valor_total }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalOrders;