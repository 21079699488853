import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAlert } from 'react-alert';
import api from '../services/api';
import { formatNumber } from '../services/helpers';

const ModalPresente = ({ keyProduto, texto, presente, setPresente, setTexto, onSave, setEmbalagem, embalagemId, setValorExtra }) => {
  const [loading, setLoading] = useState(false);
  const [arrayEmbalagens, setArrayEmbalagens] = useState([]);
  const alert = useAlert();

  async function getEmbalagens() {
    setLoading(true);
    try {
      let response = await api.get('/embalagens', {
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data.data) {
        setArrayEmbalagens(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.show('Verifique a sua conexão!', { type: 'error' });
    }
  }

  useEffect(() => {
    getEmbalagens();
  }, []);

  return (
    <div className="modal fade" id="modal-presente">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Escolher embalagem
            </h5>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body pb-0">
            <p>É pra presente?</p>
            <select value={presente} onChange={(e) => setPresente(e.target.value)} className="form-control">
              <option value="sim">Sim</option>
              <option value="nao">Não</option>
            </select>
            <br />
            {presente == 'sim' &&
              <>
                <textarea value={texto} onChange={(e) => setTexto(e.target.value)} maxLength="240" onChange={(e) => setTexto(e.target.value)} placeholder="Mensagem" style={{ height: 150 }} className="form-control"></textarea>

                <div className="table-responsive">
                  <table className="table table-hover font-size-sm border-bottom">
                    <thead>
                      <tr>
                        <th className="align-middle"></th>
                        <th className="align-middle">Imagem</th>
                        <th className="align-middle">Tipo</th>
                        <th className="align-middle">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arrayEmbalagens.map((item) =>
                        <tr>
                          <td>
                            <div className="custom-control custom-radio mb-4">
                              <input value={item.id} checked={embalagemId == item.id ? true : false} onChange={(e) => setEmbalagem(e.target.value)} className="custom-control-input" type="radio" name={"shipping-method"} />
                              <label onClick={(e) => { setEmbalagem(e.target.htmlFor); setValorExtra(parseFloat(item.valor)) }} className="custom-control-label" htmlFor={item.id}></label>
                            </div>
                          </td>
                          <td className="align-middle">
                            <img style={{maxWidth: 100}} src={item.url_fotos[0] && item.url_fotos[0]} />
                          </td>
                          <td className="align-middle">
                            {item.nome}
                          </td>
                          <td className="align-middle">
                            R$ {formatNumber(item.valor)}
                          </td>
                        </tr>
                      )}


                    </tbody>
                  </table>
                </div>
              </>
            }



            {loading &&
              <>
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
              </>
            }

            <div className="float-right mt-2 mb-2">
              <button onClick={onSave} className="btn btn-primary mt-3 mt-sm-0">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPresente;