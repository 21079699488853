import React from 'react';
import { formatNumber } from '../services/helpers';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const CardProduct = (props) => {
    const { setFavorito } = props.ProdutoStore;
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4 text-center">
            <div className="card product-card">
                {props.product.qtd_estoque <= 0 &&
                    <span className="badge badge-danger badge-shadow p-2">Esgotado</span>
                }
                <button className="btn-wishlist btn-sm" type="button" data-toggle="tooltip" onClick={() => setFavorito(props.product)} data-placement="left" title="Adicionar aos favoritos">
                    <i className="czi-heart"></i>
                </button>
                <Link className="card-img-top d-block overflow-hidden text-center" to={"/produto/" + props.product.id + "/" + props.product.slug}>
                    <img src={props.product.url_fotos[0] && props.product.url_fotos[0]} alt="Product" style={{ maxHeight: 150 }} />
                </Link>
                <div className="card-body py-2"><a className="product-meta d-block font-size-xs pb-1" href={"/produto/" + props.product.id + "/" + props.product.slug}>{props.sub ? props.product.subcategoria.nome && props.product.subcategoria.nome : props.product.categorias[0] && props.product.categorias[0].nome}</a>
                    <h3 className="product-title font-size-sm"><Link to={"/produto/" + props.product.id + "/" + props.product.slug}>{props.product.nome}</Link></h3>
                    <div className="d-flex justify-content-between">
                        <div className="product-price text-center" style={{ width: '100%' }}>
                            <span>R$ {props.product.desconto.length ? formatNumber(props.product.preco_final) : formatNumber(props.product.preco)}</span>
                        </div>
                        {(props.product.desconto.length > 0) &&
                            <del class="font-size-sm text-muted">R$ {formatNumber(props.product.preco)}</del>
                        }

                    </div>
                </div>
                <div className="card-body card-body-hidden">
                    <Link to={"/produto/" + props.product.id + "/" + props.product.slug} className="btn btn-danger btn-sm btn-block mb-2"><i className="czi-cart font-size-sm mr-1"></i>Ver mais</Link>
                </div>
            </div>
            <hr className="d-sm-none" />
        </div >
    );
}

export default inject("ProdutoStore")(observer(CardProduct));