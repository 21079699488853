import { decorate, observable, action } from "mobx";

class PedidoStore {

    endereco_id = null;
    codigo_frete = null;
    observacao = "";
    cupom = "";
    cupomDados = null;
    freteSelecionado = null;
    objectEndereco = null;
    token_cartao = false;
    cartao = '';
    bandeira = null;
    validade = '';
    cvv = '';
    type_pagamento = 1;
    cardTokenId = null;
    objectToken = null;
    nome= '';
    parcelas = [];
    qtdParcelas = 1;
    tipo_pagamento = 2;


    setEnderecoId = (id) => {
        this.endereco_id = id;
    }

    setTipoPagamento = (id) => {
        this.tipo_pagamento = id;
    }

    setParcelas = (data) => {
        this.parcelas = data;
    }

    setQtdParcelas = (qtd) =>{
        this.qtdParcelas = qtd;
    }

    setCodigoFrete = (codigo) =>{
        this.codigo_frete = codigo;
    }

    setObservacao = (text) => {
        this.observacao = text;
    }

    setCupom = (text) => {
        this.cupom = text;
    }

    setNome = (text) => {
        this.nome = text;
    }

    setDadosCupom = (data) => {
        this.cupomDados = data;
    }

    setObjectEndereco = (data) => {
        this.objectEndereco = data;
    }

    setFreteSelecionado = (data) => {
        this.freteSelecionado = data;
    }

    setTokenCartao = (text) => {
        this.token_cartao = text;
    }

    setCartao = (data) => {
        this.cartao = data;
    }

    setBandeira = (data) => {
        this.bandeira = data;
    }

    setValidade = (data) => {
        this.validade = data;
    }

    setCvv = (data) => {
        this.cvv = data;
    }

    setTypePagamento = (data) => {
        this.type_pagamento = data;
    }

    setCardTokenId = (data) => {
        this.cardTokenId = data;
    }

    
    setObjectToken = (data) => {
        this.objectToken = data;
    }

}

decorate(PedidoStore, {
    endereco_id: observable,
    observacao: observable,
    cupom: observable,
    cupomDados: observable,
    freteSelecionado: observable,
    token_cartao: observable,
    bandeira_token: observable,
    cartao: observable,
    objectEndereco: observable,
    codigo_frete: observable,
    validade: observable,
    cvv: observable,
    bandeira: observable,
    type_pagamento: observable,
    cardTokenId: observable,
    objectToken: observable,
    nome: observable,
    qtdParcelas: observable,
    parcelas: observable,
    tipo_pagamento: observable,
    

    setTipoPagamento: action,
    setEnderecoId: action,
    setObservacao: action,
    setCupom: action,
    setDadosCupom: action,
    setFreteSelecionado: action,
    setTokenCartao: action,
    setBandeiraToken: action,
    setCartao: action,
    setCvv: action,
    setBandeira: action,
    setValidade: action,
    setObjectEndereco: action,
    setTypePagamento: action,
    setCodigoFrete: action,
    setCardTokenId: action,
    setObjectToken: action,
    setNome: action,
    setParcelas: action,
    setQtdParcelas: action
});

export default new PedidoStore();