import { decorate, observable, action } from "mobx";
import _ from 'lodash';


class ProdutoStore {

    favorito = [];

    getFavorito = async () =>{
        let favoritos = await localStorage.getItem('favoritos');
        if(favoritos !== null){
            this.favorito = JSON.parse(favoritos);
        }else{
            this.favorito = [];
        }
    }

    setFavorito = async (produto) =>{
        let favoritos = this.favorito;
        if(_.findIndex(favoritos, function(o) { return o.id == produto.id; }) >= 0){
            favoritos = _.filter(favoritos, function(o) { return o.id != produto.id; });
            await localStorage.setItem('favoritos', JSON.stringify(favoritos));
            this.favorito =  favoritos;
            window.location.href = "/favoritos";
        }else{
            favoritos.push(produto);
            await localStorage.setItem('favoritos', JSON.stringify(favoritos));
            this.favorito = favoritos;
            window.location.href = "/favoritos";
        }

    }

}

decorate(ProdutoStore, {
    favorito: observable,
    getFavorito: action,
    setFavorito: action
});

export default new ProdutoStore();