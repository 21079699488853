import React from 'react';
import PageTitle from '../components/PageTitle';
import FavoriteProduct from '../components/Profile/FavoriteProduct';
import AsideProfile from '../components/Profile/AsideProfile';
import { inject, observer } from 'mobx-react';
import Toolbar from '../components/Profile/Toolbar';
import { formatNumber } from '../services/helpers';

const Favorites = (props) => {

  const { dados } = props.ClientStore;
  const { favorito, setFavorito } = props.ProdutoStore;

  console.log(favorito);

  return (
    <>
      <PageTitle nameRoute="Favoritos" url={'/favoritos'} title="Meus favoritos" />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* <!-- Sidebar--> */}
          <AsideProfile
            name={dados.nome}
            email={dados.email}
            type='favoritos'
          />
          {/* <!-- Content  --> */}
          <section className="col-lg-8">
            {/* <!-- Toolbar--> */}
            <Toolbar title="Seus itens adicionados aos favoritos:" />

            {favorito.map((item) =>
              <FavoriteProduct
                title={item.nome}
                image={item.url_fotos[0] ? item.url_fotos[0] : null }
                brand={item.marca.nome}
                deletar={() => setFavorito(item)}
                id={item.id}
                key={item.id}
                slug={item.slug}
                price={formatNumber(item.preco)}
              />
            )}

            {!favorito.length && 
               <div className="text-center">
                <br/><br/><i className="h2 text-body czi-heart"></i><br/>
                <span>Nenhum produto favoritado.</span>
              </div>
            }
          </section>
        </div>
      </div>
    </>
  );
}

export default inject('ClientStore', 'ProdutoStore')(observer(Favorites));