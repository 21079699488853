import React from 'react';

const TitleCardProduct = (props) => {
    return (
        <section className="container pt-md-3 pb-5 mb-md-3">
            <h2 className="h3 text-center">{props.title}</h2>
            <div className="row pt-4 mx-n2">
                {props.children}
            </div>
        </section>
    );
}

export default TitleCardProduct;