import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from './services/AuthRoute';

import Home from './pages/Home';
import Busca from './pages/Busca';
import Cart from './pages/Cart';
import SingleProduct from './pages/SingleProduct';
import Signin from './pages/Signin';
import CheckoutDetail from './pages/CheckoutDetail';
import CheckoutShipping from './pages/CheckoutShipping';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutConclusion from './pages/CheckoutConclusion';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import Page from './pages/Page';
import Categoria from './pages/Categoria';
import Obrigado from './pages/Obrigado';
import NotFound from './pages/NotFound';
import SubCategoria from './pages/SubCategoria';

import Favorites from './pages/Favorites';
import Addresses from './pages/Addresses';

function Routes() {

  return (
    <Switch>

      
      <Route exact path='/'>
        <Home/>
      </Route>

      <Route exact path='/obrigado'>
        <Obrigado/>
      </Route>

      <Route exact path='/busca'>
        <Busca/>
      </Route>

      <Route exact path='/entrar'>
        <Signin/>
      </Route>

      <Route path='/carrinho'>
        <Cart />
      </Route>

      <Route path='/produto/:id/:slug'>
        <SingleProduct />
      </Route>

      <Route path='/detalhe/:slug'>
        <Page />
      </Route>

      <Route path='/categoria/:slug'>
        <Categoria />
      </Route>

      <Route path='/subcategoria/:slug'>
        <SubCategoria />
      </Route>

      <AuthRoute path="/checkout-detalhes">
        <CheckoutDetail />
      </AuthRoute>

      <AuthRoute path='/checkout-endereco'>
        <CheckoutShipping />
      </AuthRoute>

      <AuthRoute path='/checkout-pagamento'>
        <CheckoutPayment />
      </AuthRoute>

      <AuthRoute path='/checkout-conclusao'>
        <CheckoutConclusion />
      </AuthRoute>

      <AuthRoute path="/perfil">
        <Profile />
      </AuthRoute>
      
      <AuthRoute path="/favoritos">
        <Favorites />
      </AuthRoute>


      <AuthRoute path="/pedidos">
        <Orders />
      </AuthRoute>

      <AuthRoute path="/enderecos">
        <Addresses />
      </AuthRoute>

      <Route path='*' exact={true} component={NotFound} />

    </Switch>

  );
}

export default Routes;
