import React from 'react';

import Breadcrumb from './Breadcrumb';

const PageTitle = (props) => {
  return (
    <div className="page-title-overlap pt-4" style={{ backgroundColor: "#fbfbfb" }}>
      <div className="container d-lg-flex justify-content-between py-2 py-lg-3">

        <div className="order-lg-2 mb-1 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-dark flex-lg-nowrap justify-content-center justify-content-lg-start text-dark">

              <Breadcrumb name="Início" link="/" isHome={true} />
              <Breadcrumb name={props.nameRoute} link={props.url} />

            </ol>
          </nav>
        </div>

        <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h5 className="mb-0">
            {/* {props.title} */}
          </h5>
        </div>
      </div>
    </div>
  );
}

export default PageTitle;