import React from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../services/helpers';

const CartDropDown = (props) => {
    const { produtos, getTotal, removeProduto } = props.CarrinhoStore;

    return (
        <div className="dropdown-menu dropdown-menu-right" style={{ width: "20rem" }}>
            <div className="widget widget-cart px-3 pt-2 pb-3">
                {produtos.map((item, key) =>
                    <div key={item.id} className="widget-cart-item pb-2 border-bottom">
                        <button onClick={() => removeProduto(key)} className="close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
                        <div className="media align-items-center"><a className="d-block mr-2" href={"/produto/"+item.id+"/"+item.slug}><img width="64" src={item.url_fotos} alt="Product" /></a>
                            <div className="media-body">
                                <h6 className="widget-product-title"><a href={"/produto/"+item.id+"/"+item.slug}>{item.nome}</a></h6>
                                <div className="widget-product-meta"><span className="text-accent mr-2">R${formatNumber(item.preco)}</span><span className="text-muted">x {item.quantidade}</span></div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                    <div className="font-size-sm mr-2 py-2"><span className="text-muted">Subtotal:</span><span className="text-accent font-size-base ml-1">R${formatNumber(getTotal)}</span></div>
                </div><a className="btn btn-danger btn-sm btn-block" href="/carrinho"><i className="czi-card mr-2 font-size-base align-middle"></i>Finalizar</a>
            </div>
        </div>
    );
}

export default inject("CarrinhoStore")(observer(CartDropDown));