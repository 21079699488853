import React from 'react';
import { Link } from 'react-router-dom';

const AsideProfile = ({ name, email, type }) => {
  return (

    <aside className="col-lg-4 pt-4 pt-lg-0">
      <div className="cz-sidebar-static rounded-lg box-shadow-lg px-0 pb-0 mb-5 mb-lg-0">
        <div className="px-4 mb-4">
          <div className="media align-items-center">
            <div className="media-body pl-3">
              <h3 className="font-size-base mb-0">
                { name }
              </h3>
              <span className="text-accent font-size-sm">
                { email }
              </span>
            </div>
          </div>
        </div>
        <div className="bg-secondary px-4 py-3">
          <h3 className="font-size-sm mb-0 text-muted">Dashboard</h3>
        </div>
        <ul className="list-unstyled mb-0">
        <li className="border-bottom mb-0">
            <Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${type === 'profile' ? 'active' : ''}`} to="/perfil">
              <i className="czi-user opacity-60 mr-2"></i>
              Informações do Perfil
            </Link>
          </li>

          <li className="border-bottom mb-0">
            <Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${type === 'addresses' ? 'active' : ''}`} to="/enderecos">
              <i className="czi-location opacity-60 mr-2"></i>
                Endereços
            </Link>
          </li>
          <li className="border-bottom mb-0">
            <Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${type === 'orders' ? 'active' : ''}`} to="/pedidos">
              <i className="czi-bag opacity-60 mr-2"></i>
                Compras
              <span className="font-size-sm text-muted ml-auto"></span>
            </Link>
          </li>
          <li className="mb-0">
            <Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${type === 'favoritos' ? 'active' : ''}`} to="/favoritos">
              <i className="czi-heart opacity-60 mr-2"></i>
                Favoritos
              <span className="font-size-sm text-muted ml-auto"></span>
            </Link>
          </li>
        </ul>

      </div>
    </aside>

  );
}

export default AsideProfile;