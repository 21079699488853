import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const CarouselBanner = ({ data }) => {

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">

        {data.map((item, key) =>
          <a key={key} href={item.link} className={key == 0 ? ' carousel-item  active bannerHome' : 'carousel-item bannerHome'} style={{ background: "url(" + item.imagem_url + ") no-repeat center top / cover" }}>

          </a>
        )}
        <a className="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleSlidesOnly" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>


      </div>
    </div>
  );
}

export default CarouselBanner;