import React, { useEffect, useState } from 'react';

import PageTitle from '../components/PageTitle';
import Step from '../components/Checkout/Step';
import CardCheckoutConclusion from '../components/Checkout/CardCheckoutConclusion';
import NavigationDesktop from '../components/Checkout/NavigationDesktop';
import AsideCheckout from '../components/Checkout/AsideCheckout';
import NavigationMobile from '../components/Checkout/NavigationMobile';
import { inject, observer } from 'mobx-react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { formatNumber, closeModal } from '../services/helpers';
import api from '../services/api';
import ModalPresente from '../components/ModalPresente';

const CheckoutConclusion = (props) => {
  const { cvv, cartao, nome, validade, bandeira, setBandeira, type_pagamento, objectToken, objectEndereco, freteSelecionado, codigo_frete, qtdParcelas, cupomDados, tipo_pagamento } = props.PedidoStore;
  const { token, dados } = props.ClientStore;
  const { produtos, cleanCart, setEmbalagem } = props.CarrinhoStore;
  const [pedido, setPedido] = useState({});
  const [keyProduto, setKeyProduto] = useState(0);
  const [textoProduto, setTexto] = useState(null);
  const [embalagemId, setEmbalagemId] = useState(null);
  const [valorExtra, setValorExtra] = useState(0);
  const [presente, setPresente] = useState(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  let history = useHistory();

  useEffect(() => {
    //valida();
    if (!tipo_pagamento) {
      alert.show('Escolha a forma de pagamento.', { type: 'info' });
      history.push('/checkout-pagamento');
    }

    if (!codigo_frete) {
      alert.show('Selecione um metodo de entrega!', { type: 'info' });
      history.push('/checkout-endereco');
    }

    window.scrollTo(0, 0)
  }, []);

  const valida = () => {
    if (type_pagamento == 1) {
      if (nome == '') {
        alert.show('Digite o nome completo do titular.', { type: 'info' });
        history.push('/checkout-pagamento');
      } else if (cartao.length < 16) {
        alert.show('Digite um cartão de crédito válido.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      } else if (validade == '' || validade.length < 9) {
        alert.show('Digite a válidade do cartão corretamente.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      } else if (bandeira == false) {
        alert.show('Bandeira de cartão não aceita.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      } else if (cvv == '') {
        alert.show('Digite o código de segurança do cartão.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      }
    } else {
      if (!objectToken) {
        alert.show('Você escolheu pagar com cartão salvo, mas não escolheu qual você quer usar.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      } else if (cvv == '') {
        alert.show('Digite o código de segurança do cartão.', { type: 'info' });
        history.push('/checkout-pagamento');
        return;
      }
    }
  }

  async function postPedido() {
    setLoading(true);
    let rota = pedido.id ? '/pedido/' + pedido.id : '/pedido';
    try {
      let response = await api.post(rota, {
        data: JSON.stringify({
          produtos: produtos,
          frete: freteSelecionado.valor,
          tipo_frete: freteSelecionado.tipo,
          tipo_pagamento: 2,
          prazo: freteSelecionado.prazo,
          type: 'cartao',
          token_cartao: (type_pagamento == 2) ? objectToken.token : false,
          bandeira_token: (type_pagamento == 2) ? objectToken.bandeira.toLowerCase() : null,
          id_cupom: cupomDados ? cupomDados.id : null,
          cvv: cvv,
          endereco: objectEndereco.id,
          cartao: {
            bandeira: bandeira,
            numero: cartao,
            titular: nome,
            validade: validade.replace(' ', '').replace(' ', ''),
            cvv: cvv,
            parcelas: qtdParcelas,
            salvar: false
          }
        })
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })

      if (response?.data?.errors) {
        setPedido(response.data.data);
        alert.show(response.data.message, { type: 'error' });
      } else {
        cleanCart();
        window.scrollTo(0, 0);
        alert.show('Aguarde, estamos lhe enviando para o ambiente seguro para realizar o pagamento do seu pedido.', { type: 'success' });
        setTimeout(() => {
          window.location.href = response.data.data.url;
        }, 1000)
      }
    } catch (error) {
      alert.show('Ocorreu um erro desconhecido em nosso(s) sistema(s). ', { type: 'error' });
    }
  }

  function saveEmbalagem(){
    if(presente == 'sim' && !embalagemId){
      alert.show('Selecione a embalagem.', { type: 'error' });
      return;
    }
    setEmbalagem(keyProduto, textoProduto, presente, embalagemId, valorExtra); 
    closeModal('#modal-presente');
  }

  return (
    <>
      <ModalPresente valorExtra={valorExtra} setValorExtra={(valor) => setValorExtra(valor)} embalagemId={embalagemId} setEmbalagem={(e) => setEmbalagemId(e)} keyProduto={keyProduto} texto={textoProduto} presente={presente} setTexto={(texto) => setTexto(texto)} setPresente={(value) => setPresente(value)} onSave={() => saveEmbalagem()} />
      <PageTitle nameRoute="Conclusão" url={'/checkout-conclusao'} title="Concluir compra" />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* <!-- Steps--> */}

            <Step numberCurrent="4" />

            {/* <!-- Order details--> */}
            <h2 className="h6 pt-1 pb-3 mb-3 border-bottom">
              Resumo da sua compra
            </h2>
            {/* <!-- Item--> */}

            {produtos.map((item, key) =>
              <CardCheckoutConclusion
                key={item.id}
                id={item.id}
                item={item}
                slug={item.slug}
                img={item.url_fotos}
                price={formatNumber(item.desconto.length ? item.preco_final : item.preco)}
                amount={item.quantidade}
                title={item.nome}
                setKeyProduto={() => {setKeyProduto(key); setTexto(item.texto_presente); setPresente(item.presente); setValorExtra(item.valor_extra); setEmbalagemId(item.embalagem_id)}}
              />
            )}



            {/* <!-- Client details--> */}
            <div className="bg-secondary rounded-lg px-4 pt-4 pb-2">
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="h6">Enviar para:</h4>
                  <ul className="list-unstyled font-size-sm">
                    <li><span className="text-muted">Cliente:&nbsp;</span>{dados.nome}</li>
                    <li><span className="text-muted">Endereço:&nbsp;</span>{objectEndereco ? objectEndereco.rua + ' ' + objectEndereco.numero + ' ' + objectEndereco.bairro + ' / ' + objectEndereco.cidade + ' ' + objectEndereco.estado : ''}</li>
                    <li><span className="text-muted">E-mail:&nbsp;</span>{dados.email}</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <h4 className="h6">Método de Pagamento:</h4>
                  <ul className="list-unstyled font-size-sm">
                    {tipo_pagamento == 2 ?
                      <li><span className="text-muted">Forma de pagamento:&nbsp;</span>MercadoPago</li>
                      :
                      <li><span className="text-muted">Cartão de Crédito:&nbsp;</span>**** **** **** {type_pagamento == 1 ? cartao.slice(-4) : objectToken ? objectToken.cartao : ''} </li>
                    }

                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Navigation (desktop)--> */}
            <NavigationDesktop
              onClick={() => postPedido()}
              linkBack="/checkout-endereco"
              linkNext="/"
              textBack="Voltar ao pagamento"
              textNext="Concluir"
              loading={loading}
            />
          </section>
          {/* <!-- Sidebar--> */}
          <AsideCheckout
            descount="0.0"
            subtotal="60.00"
            shipping="10.00"
            total="70.00"
            products={false}
          />
        </div>
        {/* <!-- Navigation (mobile)--> */}
        <NavigationMobile
          onClick={() => postPedido()}
          linkBack="/checkout-endereco"
          linkNext="/"
          textBack="Voltar ao pagamento"
          textNext="Concluir"
          loading={loading}
        />
      </div>
    </>
  );
}

export default inject("PedidoStore", "ClientStore", "CarrinhoStore")(observer(CheckoutConclusion));