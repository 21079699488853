import React, { useState } from 'react';
import api from '../../services/api';
import { inject, observer } from 'mobx-react';
import ButtonLoading from '../ButtonLoading';

const Toolbar = ({ title, ClientStore }) => {

  const { logout, token } = ClientStore;

  const [loading, setLoading] = useState(false);

  const handleLogout = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await api.get('/logout', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
  
      if (response.data.message) {
        logout();
        setLoading(false);
        window.location.href="/";
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return(
    <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-4">
      <h6 className="font-size-base mb-0">
        { title }
      </h6>

      <ButtonLoading
        class="btn btn-primary btn-sm"
        title="Sair"
        loading={loading}
        onClick={handleLogout}
      />
    </div>
  );
}

export default inject('ClientStore')(observer(Toolbar));