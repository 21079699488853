import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie'


const AuthRoute = ({logged,  ...props}) => {

    return (
        <Route {...props} >
            {Cookies.get('token')
                ? props.children
                : <Redirect to='/entrar' />
            }
        </Route>
    );
}

export default AuthRoute;