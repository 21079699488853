import React from 'react';

import PageTitle from '../components/PageTitle';
import PageCartContent from '../components/Checkout/PageCartContent';

const Cart = () => {
  return (
    <>
      <PageTitle nameRoute="Carrinho" url={'/carrinho'} title="Seu carrinho"/>
        
      <PageCartContent />

    </>
    
  );
}

export default Cart;