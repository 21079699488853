import React, { useState } from 'react';
import ButtonLoading from '../ButtonLoading';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import { closeModal } from '../../services/helpers';
import config from '../../config';

const ModalReclamar = ({ id, token }) => {

  const [texto, setTexto] = useState('');
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  async function send() {
    setLoading(true);
    try {
      let response = await api.post('/problema', {
        texto: texto,
        loja_id: config.loja_id,
        pedido_id: id
      },{
        headers:{
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
      });

      if (response.data) {
        setTexto("");
        setLoading(false);
        closeModal('#order-reclamar');
        alert.success('Seu problema foi enviado com sucesso, aguarde nosso retorno!');
      }
    } catch (error) {
      setLoading(false);
      alert.error('Verifique sua conexão.');
    }

  }

  return (
    <div className="modal fade" id="order-reclamar">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Reclamar pedido #{id}
            </h5>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body pb-0">
            <textarea value={texto} onChange={(e) => setTexto(e.target.value)} placeholder="Mensagem" style={{ height: 150 }} className="form-control"></textarea>
            <div className="float-right mt-2 mb-2">
            <ButtonLoading
              class="btn btn-primary mt-3 mt-sm-0"
              onClick={() => send()}
              title="Enviar"
              loading={loading}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalReclamar;