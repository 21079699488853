import React from 'react';
import { Link } from 'react-router-dom';


const CardCheckoutConclusion = ({ price, amount, title, img, slug, id, setKeyProduto, item }) => {
  return (
    <div className="d-sm-flex justify-content-between my-4 pb-3 border-bottom">
      
      <div className="media media-ie-fix d-block d-sm-flex text-center text-sm-left">
        <Link className="d-inline-block mx-auto mr-sm-4" to={"/produto/" + id + "/" + slug} style={{ width: '10rem' }}>
          <img src={img} alt="Product" />
        </Link>
        <div className="media-body pt-2">
          <h3 className="product-title font-size-base mb-2">
            <Link to={"/produto/" + id + "/" + slug}>
              {title}
            </Link>
          </h3>
          <span>{item.estampa} {item.cor_string} / {item.tamanho_string} {item.personalizado}</span>

          <div className="font-size-lg text-accent pt-2">
            R$ {price}
          </div>
          {/* <div className="font-size-lg text-accent pt-2">
            <a href="#modal-presente" data-toggle="modal" onClick={setKeyProduto} className="btn btn-outline-primary btn-sm pl-2">Adicionar embalagem</a>
          </div> */}
        </div>
      </div>
      <div className="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-right" style={{ maxWidth: '9rem' }}>
        <p className="mb-0"><span className="text-muted font-size-sm">
          Quantidade:</span><span>&nbsp;{amount}</span>
        </p>
      </div>
    </div>
  );
}

export default CardCheckoutConclusion;