import React, { useState } from 'react';
import api from '../services/api';
import ButtonLoading from '../components/ButtonLoading';
import { useAlert } from 'react-alert';
import { IsEmail, closeModal } from '../services/helpers';

const ModalEsqueci = (props) => {

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  async function logar() {
    if (email == '' || !IsEmail(email)) {
      alert.show('Preencha o campo com e-mail válido.', { type: 'info' });
      return;
    }
    setLoading(true);
    try {
      let response = await api.post('/esqueci', {
        email: email
      });

      if (response.data.message) {
        setLoading(false);
        alert.show(response.data.message, { type: response.data.errors ? 'error' : 'success' });
        closeModal("#esqueci-modal");
      }
    } catch (error) {
      setLoading(false);
      alert.show('Ocorreu um erro desconhecido.', { type: 'error' });
    }

  }

  return (
    <div className="modal fade" id="esqueci-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">

          <div className="modal-header">
            <ul className="nav nav-tabs card-header-tabs" role="tablist">
              <li className="nav-item"><a className="nav-link active" href="#signin-tab" data-toggle="tab" role="tab" aria-selected="true"><i className="czi-unlocked mr-2 mt-n1"></i>Esqueci a senha</a></li>
            </ul>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>

          <div className="modal-body tab-content py-4">
            <form className="needs-validation tab-pane fade show active" autoComplete="off" noValidate id="signin-tab" >
              <div className="form-group">
                <label htmlFor="si-email">Endereço de Email</label>
                <input className="form-control" type="email" id="si-email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email@example.com" required />
                <div className="invalid-feedback">
                  Por favor, insira um endereço de email válido.
                </div>
              </div>
              <ButtonLoading onClick={() => logar()} title="Entrar" class="btn btn-primary btn-block btn-shadow" type="submit" loading={loading} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEsqueci;