import React, { useState } from 'react';
import api from '../services/api';
import ButtonLoading from '../components/ButtonLoading';
import { inject, observer } from 'mobx-react';
import { useAlert } from 'react-alert';
import { IsEmail } from '../services/helpers';
import InputMask from 'react-input-mask';
import ModalEsqueci from '../components/ModalEsqueci';

const Signin = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingCadastro, setLoadingCadastro] = useState(false);
    const [emailCadastro, setEmailCadastro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
	const [senha, setSenha] = useState('');
	const [resenha, setResenha] = useState('');
    const { setToken, setDados } = props.ClientStore;
    const { produtos } = props.CarrinhoStore;
    const alert = useAlert();

    async function logar() {
        if (email == '' || !IsEmail(email)) {
            alert.show('Preencha o campo com e-mail válido.', { type: 'info' });
            return;
        } else if (password == '' || password.length < 6) {
            alert.show('Preencha o campo senha com minimo 6 caracteres.', { type: 'info' });
            return;
        }

        setLoading(true);
        try {
            let response = await api.post('/login', {
                email: email,
                password: password
            });

            if (response.data.token) {
                setLoading(false);
                setToken(response.data.token);
                setDados(response.data.data);
                if (produtos.length > 0) {
                    window.location.replace('/carrinho');
                } else {
                    window.location.replace('/perfil');
                }
            }
        } catch (error) {
            setLoading(false);
            alert.show('E-mail ou senha inválida.', { type: 'error' });
        }

    }

    async function cadastrar() {
		if (nome == '' || !nome.includes(' ')) {
            alert.show('Preencha o campo nome e sobrenome.', { type: 'info' });
			return;
		} else if (emailCadastro == '' || !IsEmail(emailCadastro)) {
            alert.show('Preencha o campo com e-mail válido.', { type: 'info' });
			return;
		} else if (telefone == '' || telefone.length < 15) {
            alert.show('Preencha o campo com telefone válido.', { type: 'info' });
			return;
		} else if (cpf == '' || cpf.length < 14) {
            alert.show('Preencha o campo com CPF válido.', { type: 'info' });
			return;
		} else if (senha == '' || senha.length < 6) {
            alert.show('Preencha o campo senha com minimo 6 caracteres.', { type: 'info' });
			return;
		}else if(senha != resenha){
            alert.show('As duas senhas são diferente!', { type: 'info' });
			return;
		}

		setLoadingCadastro(true);
		try {
			let response = await api.post('/cliente', {
				nome,
				email: emailCadastro,
				telefone,
				cpf,
				password: senha
			});

			setLoadingCadastro(false);
			if (!response.data.errors) {
				if(response.data.data.token){
					setToken(response.data.data.token);
					setDados(response.data.data.data);
				}
                if (produtos.length > 0) {
                    window.location.replace('/carrinho');
                } else {
                    window.location.replace('/perfil');
                }
			} else {
                alert.show(response.data.message, { type: 'info' });
			}
		} catch (error) {
            setLoadingCadastro(false);
            alert.show('Ocorreu um erro ao se cadastrar!', { type: 'error' });
		}

	}

    return (
        <div className="container py-4 py-lg-5 my-4">
            <ModalEsqueci />
            <div className="row">
                <div className="col-md-6">
                    <div className="card border-0 box-shadow" style={{backgroundColor: '#e1f5ed'}}>
                        <div className="card-body">
                            <h2 className="h4 mb-1">Entrar</h2>
                            <div className="py-3">
                            </div>
                            <form className="needs-validation" noValidate>
                                <div className="input-group-overlay form-group">
                                    <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="czi-mail"></i></span></div>
                                    <input className="form-control prepended-form-control" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" required />
                                </div>
                                <div className="input-group-overlay form-group">
                                    <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="czi-locked"></i></span></div>
                                    <div className="password-toggle">
                                        <input className="form-control prepended-form-control" value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" required />
                                        <label className="password-toggle-btn">
                                            <input className="custom-control-input" type="checkbox" /><i className="czi-eye password-toggle-indicator"></i><span className="sr-only">Mostrar senha</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <a className="nav-link-inline font-size-sm" href="#esqueci-modal" data-toggle="modal">Esqueceu a senha?</a>
                                </div>
                                <hr className="mt-4" />
                                <div className="text-right pt-4">
                                    <ButtonLoading onClick={() => logar()} title="Entrar" class="btn btn-danger" loading={loading} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-4 mt-3 mt-md-0">
                    <h2 className="h4 mb-3">
                        Não tem conta? Inscreva-se
                    </h2>
                    <p className="font-size-sm text-muted mb-4">O registro leva menos de um minuto, mas oferece controle total sobre seus pedidos.</p>
                    <form className="needs-validation" noValidate>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-fn">Nome</label>
                                    <input value={nome} onChange={(e) => setNome(e.target.value)} className="form-control" type="text" required id="reg-fn" placeholder="Nome" />
                                    <div className="invalid-feedback">Please enter your first name!</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-phone">Telefone</label>
                                    <InputMask className="form-control" mask="(99) 99999-9999" maskChar={null} value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder="Telefone" />
                                    <div className="invalid-feedback">Please enter your phone number!</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-email">E-mail</label>
                                    <input  value={emailCadastro} onChange={(e) => setEmailCadastro(e.target.value)} className="form-control" type="email" required id="reg-email" placeholder="E-mail" />
                                    <div className="invalid-feedback">Please enter valid email address!</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-email">CPF</label>
                                    <InputMask className="form-control" mask="999.999.999-99" maskChar={null} value={cpf} onChange={(e) => setCpf(e.target.value)} placeholder="CPF" />
                                    <div className="invalid-feedback">Please enter valid email address!</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-password">Senha</label>
                                    <input  value={senha} onChange={(e) => setSenha(e.target.value)} className="form-control" type="password" required id="reg-password" placeholder="Senha" />
                                    <div className="invalid-feedback">Please enter password!</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="reg-password-confirm">Confirme a senha</label>
                                    <input  value={resenha} onChange={(e) => setResenha(e.target.value)} className="form-control" type="password" required id="reg-password-confirm" placeholder="Confirme a senha" />
                                    <div className="invalid-feedback">Passwords do not match!</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <ButtonLoading onClick={() => cadastrar()} title="Cadastrar" class="btn btn-danger" loading={loadingCadastro} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default inject("ClientStore", "CarrinhoStore")(observer(Signin));