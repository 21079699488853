import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { FaRegTimesCircle } from "react-icons/fa";
import Modal from "react-modal";
import "./index.css";

import Routes from "./routes";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { FaClosedCaptioning } from "./../node_modules/react-icons/fa/index.esm";

const App = (props) => {
  const { getToken, getDados } = props.ClientStore;
  const { getProdutosCarrinho } = props.CarrinhoStore;
  const { getFavorito } = props.ProdutoStore;
  const { getMenus } = props.HeaderStore;
  const [config, setConfig] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setTimeout(() => {
      setIsOpen(true);
    }, 2000);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function initialConfig() {
    getMenus();
    await getToken();
    await getDados();
    await getProdutosCarrinho();
    await getFavorito();
    setConfig(true);
  }

  const options = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: "10px",
    transition: transitions.SCALE,
  };

  useEffect(() => {
    // openModal();
    initialConfig();
  }, []);

  const AlertTemplate = ({ style, options, message, close }) => {
    let type = null;
    let icon = null;
    if (options.type === "info") {
      type = "info";
      icon = "czi-bell";
    } else if (options.type === "success") {
      type = "success";
      icon = "czi-check-circle";
    } else if (options.type === "error") {
      type = "danger";
      icon = "czi-close-circle";
    }

    return (
      <div
        style={style}
        className={`alert alert-${type} alert-dismissible fade show alert-with-icon`}
        role="alert"
      >
        <div className="alert-icon-box">
          <i className={`alert-icon ${icon}`}></i>
        </div>
        {message}
        <button
          onClick={close}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "750px",
      width: "100%",
      overflow: "hidden",
      position: "absolute",
    },
  };

  if (config) {
    return (
      <>
        <BrowserRouter>
          <AlertProvider template={AlertTemplate} {...options}>
            <Header />
            <Routes />
            <Footer />
          </AlertProvider>
        </BrowserRouter>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <h3
            style={{ color: "#49b2ba", cursor: "pointer" }}
            onClick={closeModal}
          >
            Fechar
          </h3>
          <img src="/img/popup/pascoa.jpg" alt="promoção pascoa" />
        </Modal>
      </>
    );
  }
};

export default inject(
  "ClientStore",
  "CarrinhoStore",
  "ProdutoStore",
  "HeaderStore"
)(observer(App));
