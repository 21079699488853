import React, { useState, useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import NavigationMobile from '../components/Checkout/NavigationMobile';
import AsideCheckout from '../components/Checkout/AsideCheckout';
import TypeShipping from '../components/Checkout/TypeShipping';
import NavigationDesktop from '../components/Checkout/NavigationDesktop';
import Step from '../components/Checkout/Step';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useAlert } from 'react-alert';
import api from '../services/api';
import { formatNumber } from '../services/helpers';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';


const CheckoutShipping = (props) => {
  const [loading, setLoading] = useState(false);
  const [objectFretes, setObjectFretes] = useState([]);
  const { produtos } = props.CarrinhoStore;
  const { objectEndereco, endereco_id, setFreteSelecionado, codigo_frete, setCodigoFrete, setParcelas } = props.PedidoStore;
  const { getTotal } = props.CarrinhoStore;
  let history = useHistory()
  const alert = useAlert();

  const changeCheck = (e) => {
    let index = _.findIndex(objectFretes, function (o) { return o.codigo == e.target.htmlFor; });
    setCodigoFrete(e.target.htmlFor);
    setFreteSelecionado(objectFretes[index]);
    getParcelas(objectFretes[index].valor)
  }

  async function getFretes() {
    try {
      setLoading(true);
      let response = await api.post('/frete-carrinho', {
        produtos: JSON.stringify(produtos),
        cep_destino: objectEndereco.cep,
        loja: produtos[0].loja_id
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.data) {

        if (produtos[0].taxa_entrega == 0 || produtos[0].entrega_gratis == 1) {

          if (produtos[0].taxa_entrega == 0) {
            setObjectFretes([{
              "codigo": 2,
              "valor": 0.00,
              "prazo": "Sem entrega (Produto online)",
              "tipo": "Sem entrega (Produto online)"
            }]);
          }

          if (produtos[0].entrega_gratis == 1) {
            setObjectFretes([{
              "codigo": 3,
              "valor": 0.00,
              "prazo": "Entrega grátis",
              "tipo": "Entrega grátis"
            }]);
          }

        } else {
          setObjectFretes(response.data.data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.show('Verifique sua conexão!', { type: 'error' });
    }
  }

  function getParcelas(frete) {
		let newArray = [];
		let parcelas = 12;
		for (let index = 1; index < parcelas + 1; index++) {
			newArray.push({
				label: index + 'x de R$ ' + formatNumber((frete + getTotal) / index),
				value: index
			})
		}
		setParcelas(newArray);
	}

  useEffect(() => {
    if (!endereco_id) {
      alert.show('Selecione um endereço!', { type: 'info' });
      history.push('/checkout-detalhes');
    } else {
      getFretes();
    }
    window.scrollTo(0, 0)


  }, []);

  return (
    <>
      <PageTitle nameRoute="Entrega" url={'/checkout-endereco'} title="Concluir compra" />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">

            <Step numberCurrent="3" />

            <h2 className="h6 pb-3 mb-2">
              Escolher método de envio
            </h2>
            <div className="table-responsive">
              <table className="table table-hover font-size-sm border-bottom">
                <thead>
                  <tr>
                    <th className="align-middle"></th>
                    <th className="align-middle">Tipo</th>
                    <th className="align-middle">Prazo</th>
                    <th className="align-middle">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {(objectFretes.length > 0) &&
                    objectFretes.map((item) =>
                      <TypeShipping
                        key={item.codigo}
                        checked={codigo_frete == item.codigo ? true : false}
                        change={(e) => changeCheck(e)}
                        value={item.codigo}
                        price={formatNumber(item.valor)}
                        name={item.prazo}
                        type={item.tipo}
                        description=""
                      />
                    )
                  }
                </tbody>
              </table>
            </div>

            {loading &&
              <>
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
              </>
            }

            {(!objectFretes.length && !loading) &&
              <div className="text-center">
                <span>Nenhum frete encontrado para esse endereço.</span>
              </div>
            }

            <NavigationDesktop
              linkNext='/checkout-conclusao'
              linkBack='/checkout-detalhes'
              textNext='Avançar para o Pagamento'
              textBack='Voltar aos detalhes'
            />

          </section>

          <AsideCheckout
            subtotal="60.00"
            shipping="10.00"
            descount="0.0"
            total="70.00"
          />
        </div>

        <NavigationMobile
          linkNext='/checkout-conclusao'
          linkBack='/checkout-detalhes'
          textNext='Avançar para o Pagamento'
          textBack='Voltar aos detalhes'
        />

      </div>
    </>
  );
}

export default inject("PedidoStore", "CarrinhoStore")(observer(CheckoutShipping));